import React, { CSSProperties } from "react";
import { hexColors } from "../constants/colors";
import { WaypointDetail } from "../types";
import Pin from "./atoms/Pin";

type Props = {
  n: number;
};
export default function PositionIndicator({ n }: Props) {
  const style: CSSProperties = {
    transform: `translateX(-50%) translateY(-90%)`
  };
  const styleTop: CSSProperties = {
    top: 0,
    left: "50%",
    transform: "translateX(-50%) translateY(-160%)",
    textAlign: "center"
  };
  return (
    <div className="absolute bg-transparent text-xs" style={style}>
      <div className="absolute leading-none" style={styleTop}>
        <p className="text-stroke font-semibold">You are here</p>
      </div>
      <Pin
        className="focused"
        number={n}
        style={{
          marginBottom: -3,
          color: hexColors.selectedElementLabel
        }}
      />
    </div>
  );
}
