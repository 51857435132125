export enum BayIndex {
  aisle = 0,
  bay = 1,
  polygon = 2,
  area = 3,
  name = 4
}
export enum BayLocationIndex {
  aisle = 0,
  bay = 1
}
