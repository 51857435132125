import { Point2D } from "@deck.gl/core";

export const getSegmentKey = (n1: string, n2: string) => {
  return [n1, n2].join("-");
}

export const getDistance = ([x1, y1, z1 = 0]: Point2D, [x2, y2, z2 = 0]: Point2D) => {
  //z is storey number (eg: 1, 2, 3) which is too small compared to x, y
  // use x1 as scale
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2 + ((z1 -z2) * x1) ** 2);
}

export const getAngle = ([x1, y1]: Point2D, [x2, y2]: Point2D) => {
  return (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
}
