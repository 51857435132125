import React, { ReactNode, CSSProperties } from "react";
import { Point, Color } from "@deck.gl/core";
import { DeckGLChildProps, DeckGLChildFunctionComponent } from "@deck.gl/react";
import Pin from "./atoms/Pin";
import colors, { rgbToHex } from "../constants/colors";
import { Dictionary } from "../types";
import { LabelPosition } from "../types";

const LABEL_STYLES: Dictionary<CSSProperties> = {
  left: {
    left: "-0.25rem",
    top: "50%",
    transform: "translateX(-100%) translateY(-50%)",
    textAlign: "right"
  },
  top: {
    top: 0,
    left: "50%",
    transform: "translateX(-50%) translateY(-100%)",
    textAlign: "center"
  },
  right: {
    right: "-0.25rem",
    top: "50%",
    transform: "translateX(100%) translateY(-50%)",
    textAlign: "left"
  },
  bottom: {
    bottom: "0.125rem",
    left: "50%",
    transform: "translateX(-50%) translateY(100%)",
    textAlign: "center"
  }
};

interface Props {
  point: Point;
  color?: Color;
  icon?: ReactNode;
  iconPosition?: "bottom" | "center";
  label?: string;
  labelPosition?: LabelPosition;
  labelClassName?: string;
  zoom: number;
}

interface FullProps extends Props, DeckGLChildProps {}

const MapLocation: DeckGLChildFunctionComponent<Props> = (props) => {
  const {
    point,
    color = colors.mapLocation,
    icon = <Pin />,
    iconPosition = "bottom",
    label,
    labelPosition = LabelPosition.left,
    labelClassName,
    viewport
  } = props as FullProps;
  const [left, top] = viewport.project(point);

  const yOffset = iconPosition === "center" ? -50 : -100;
  const style: CSSProperties = {
    left,
    top,
    transform: `translateX(-50%) translateY(${yOffset}%)`,
    color: rgbToHex(color)
  };
  return (
    <div className="absolute bg-transparent text-xs" style={style}>
      {label && (
        <div className="absolute leading-none" style={LABEL_STYLES[labelPosition]}>
          <p className={"text-stroke " + (labelClassName ?? "")}>{label?.replace(/ &/, " &")}</p>
        </div>
      )}
      {icon}
    </div>
  );
};

MapLocation.deckGLViewProps = true;

export type MapLocationProps = Props;
export default MapLocation;
