import { Point } from "@deck.gl/core";
import { FONT_FAMILY } from "../../constants/style";
import { LabelPosition, Rectangle, Size } from "../../types";

export interface VisibilityProps {
  name: string;
  zoom: number;
  point: Point;
  scale?: number;
  labelPosition?: LabelPosition;
  labels?: ProjectedLabel[];
  includePin?: boolean;
  alwaysShow?: boolean;
}

export interface ProjectedLabel {
  projectedPosition: Rectangle;
  name: string;
}

export type PinSize = number[];

export function showAisleNumbers(zoom: number) {
  return zoom > -8;
}
export function sortLandmark(first: string, second: string): number {
  const firstPriority = landMarkPriority(first);
  const secondPriority = landMarkPriority(second);

  if (firstPriority < secondPriority) return -1;
  if (firstPriority > secondPriority) return 1;
  return 0;
}

// show landmark with higher priority (smaller ranking eg: 0 is the highest priority)
// and hide the lower one if they overlap
export function landMarkPriority(name: string): number {
  if (!name || /\b(entrance)\b/i.test(name)) return 0;

  switch (name.toLowerCase().replace("'", "")) {
    case "toilets":
      return 10;
    case "special orders desk":
      return 20;
    case "click & collect desk":
      return 30;
    case "paint desk":
      return 40;
    case "information desk":
      return 50;
    default:
      return 100;
  }
}

//Get minimum zoom level to start showing those landmark
export function showLandmarkLabel(name: string, zoom: number) {
  if (!name || /\b(entrance)\b/i.test(name)) return true;

  switch (name.toLowerCase().replace("'", "")) {
    case "special orders desk":
    case "toilets":
    case "paint desk":
    case "information desk":
    case "toolshop":
    case "tool shop":
    case "nursery":
    case "green life":
    case "greenlife":
    case "paint":
    case "timber":
    case "timber yard":
    case "timber & trade sales":
    case "electrical":
      return true;
    case "plumbing":
    case "leisure":
    case "flooring":
    case "cleaning":
    case "fixings":
    case "garden tools":
    case "garden care":
    case "doors":
    case "cafe":
    case "hardware cafe":
    case "trade desk":
      return zoom > -8.5;
    case "registers":
      return zoom > -8;
    default:
      return zoom > -7.5;
  }
}

//determine if this landmark overlaps with other shown landmark on map
// return undefined if it overlaps
export function checkLabelEligibility(props: VisibilityProps): ProjectedLabel | undefined {
  //check if name is in lookup
  const { name, zoom, scale, labelPosition, point, labels, includePin, alwaysShow } = props;

  const validZoomLevel = showLandmarkLabel(name, zoom);

  if (!validZoomLevel && !alwaysShow) {
    return;
  }
  //iconsize: 20 * 32
  const textSize = getTextSize(name, scale);
  const projectedPoint = getProjectedPoint(
    point,
    textSize,
    includePin ? [20, 32] : [0, 0],
    labelPosition
  );

  const overlap = alwaysShow
    ? null
    : labels!.find((item) => {
        const shownRectangle = item.projectedPosition || { x: 0, y: 0, width: 0, height: 0 };
        if (isOverlap(projectedPoint, shownRectangle)) {
          return true;
        }
        return false;
      });

  if (!overlap) {
    return {
      name,
      projectedPosition: projectedPoint
    };
  }
  return;
}

// translate the xCoord and yCoord to a rectangle on current map screen
// with width/ height in pixel; x, y are the top left corner of the rectangle
function getProjectedPoint(
  point: Point,
  textSize: Size,
  pinSize: PinSize,
  labelPosition?: LabelPosition
): Rectangle {
  const [iconWidth, iconHeight] = pinSize;
  const [xCoord, yCoord] = point;
  let [width, height] = [0, 0];
  const [x, y] = [0, 0];

  let projectedPoint = { x, y, width, height };

  switch (labelPosition) {
    case LabelPosition.center:
      width = textSize.width + iconWidth;
      height = Math.max(textSize.height, iconHeight);
      projectedPoint = {
        width,
        height,
        y: yCoord - height / 2,
        x: xCoord - width / 2
      };
      break;
    case LabelPosition.right:
      width = textSize.width + iconWidth;
      height = Math.max(textSize.height, iconHeight);
      projectedPoint = {
        width,
        height,
        y: yCoord - height,
        x: xCoord - iconWidth / 2
      };
      break;
    case LabelPosition.top:
      width = Math.max(textSize.width, iconWidth);
      height = textSize.height + iconHeight;
      projectedPoint = {
        width,
        height,
        y: yCoord - height,
        x: xCoord - width / 2
      };
      break;
    case LabelPosition.bottom:
      width = Math.max(textSize.width, iconWidth);
      height = textSize.height + iconHeight;
      projectedPoint = {
        width,
        height,
        y: yCoord - iconHeight,
        x: xCoord - width / 2
      };
      break;
    default:
      width = textSize.width + iconWidth;
      height = Math.max(textSize.height, iconHeight);
      projectedPoint = {
        width,
        height,
        y: yCoord - height,
        x: xCoord - width + iconWidth / 2
      };
      break;
  }
  return projectedPoint;
}

//determine if first rectangle overlaps second one.
// x, y are top left point of each rectangle
// y axis on map is descending (top left of map is 0,0)
function isOverlap(first: Rectangle, second: Rectangle): boolean {
  const { x: firstMinX, y: firstMinY, width: fWidth, height: fHeight } = first;
  const [firstMaxX, firstMaxY] = [firstMinX + fWidth, firstMinY + fHeight];

  const { x: secondMinX, y: secondMinY, width: sWidth, height: sHeight } = second;
  const [secondMaxX, secondMaxY] = [secondMinX + sWidth, secondMinY + sHeight];

  // leave a gap of 6 pixel so that it's not too crowded
  const gap = 6;
  const secondAboveFirst = firstMinY - secondMaxY > gap;
  const secondRightOfFirst = secondMinX - firstMaxX > gap;
  const secondBelowFirst = secondMinY - firstMaxY > gap;
  const secondLeftOfFirst = firstMinX - secondMaxX > gap;

  /* alternatively
  return (
    first.x <= second.x + second.width + gap &&
    first.x + first.width + gap >= second.x &&
    first.y <= second.y + second.height + gap &&
    first.y + first.height + gap >= second.y
  ); */

  return !(secondAboveFirst || secondRightOfFirst || secondBelowFirst || secondLeftOfFirst);
}

function getTextSize(text: string, size = 12): Size {
  if (text?.length <= 0) {
    return { width: 0, height: 0 };
  }
  const words = text.split(" ");
  const longestWord = words.sort((a, b) => b.length - a.length)[0];

  const canvas = document.createElement("canvas");
  const context = canvas?.getContext("2d");

  if (!context) {
    //this is fallback guesswork/ estimation that works with current text size (8-14)
    //and zoom level (-5 - -8.5)
    const letterWidth = (size / 3) * 2;
    return { height: size * words.length, width: (longestWord.length + 1) * letterWidth };
  }

  context!.font = `${size}px ${FONT_FAMILY}`;
  const width = context!.measureText(longestWord).width;
  const height = size * words.length;
  return { width, height };
}
