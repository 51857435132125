import { useMemo } from "react";
import { ListItem, StoreData } from "../types";

export function useList(list?: ListItem[], store?: StoreData): ListItem[] | undefined {
  return useMemo(() => {
    if (!store?.defaultLandmarkAddress) {
      return list;
    }

    const newList = [] as ListItem[];

    if (list) {
      list.forEach((item) => {
        newList.push({
          ...item,
          address: store.defaultLandmarkAddress[item.address] || item.address
        });
      });
    }

    return newList;
  }, [store, list]);
}

export function useStartAddress(startAddress?: string, store?: StoreData): string | undefined {
  return useMemo(() => {
    if (!store?.defaultLandmarkAddress) {
      return startAddress;
    }

    return startAddress ? store.defaultLandmarkAddress[startAddress] || startAddress : startAddress;
  }, [store, startAddress]);
}

export function useFocusAddress(focusAddress?: string, store?: StoreData): string | undefined {
  return useMemo(() => {
    if (!store?.defaultLandmarkAddress) {
      return focusAddress;
    }

    return focusAddress ? store.defaultLandmarkAddress[focusAddress] || focusAddress : focusAddress;
  }, [store, focusAddress]);
}

export function useLocation(location?: string, store?: StoreData): string | undefined {
  return useMemo(() => {
    if (!store?.defaultLandmarkAddress) {
      return location;
    }

    return location ? store.defaultLandmarkAddress[location] || location : location;
  }, [store, location]);
}
