import { useState } from "react";
import { Size } from "../types";

export function useElementSize(): [Size | undefined, (element: Element | null) => void] {
  const [size, setSize] = useState<Size>();

  function getSize(element: Element | null) {
    if (!element) return;

    const [width, height] = [element.clientWidth, element.clientHeight];
    if (width !== size?.width || height !== size.height) {
      setSize({ width, height });
    }
  }

  return [size, getSize];
}
