import { Point3D } from "@deck.gl/core";
import { rotateArray } from "..";
import { MapNode } from "../../types";

const compareNodeCost = (a: MapNode, b: MapNode): number => {
  return a[0][0] - b[0][0] || a[0][1] - b[0][1];
};

const sortNodeOnSameLevel = (nodesOnLevel: MapNode[]): MapNode[] => {
  nodesOnLevel.sort((a, b) => compareNodeCost(a, b));
  return nodesOnLevel;
};

const groupNodesByStorey = (destinations: MapNode[]): Map<number, MapNode[]> => {
  // group by level
  const groupedNodes = new Map<number, MapNode[]>();
  destinations.forEach(([[x, y, level], address]) => {
    if (groupedNodes.has(level)) {
      const nodesOnLevel = groupedNodes.get(level);
      nodesOnLevel!.push([[x, y, level], address]);
    } else {
      groupedNodes.set(level, [[[x, y, level], address]]);
    }
  });
  return groupedNodes;
};

const bringStartingStoreyForward = (
  startPoint: Point3D,
  groupedNodes: Map<number, MapNode[]>
): number[] => {
  const startLevel = startPoint[2];
  // there are only a few so sorting wont harm
  const levels = Array.from(groupedNodes.keys()).sort((a, b) => a - b);
  rotateArray(levels, levels.indexOf(startLevel));
  return levels;
};

// NOTE: this is a NP-hard problem same as TSP (travelling salesman problem)
// there is not polynomial time optimal solutions so trying a greedy suboptimal solutions
// if we need to add toolshop logic we need find out which level it is and take those points out of the level MapNode (keeping the same order)
// push back either to front or end of the array based on the closeness from the first item or last item of the array
/* eslint-disable @typescript-eslint/no-unused-vars */
export const findDesiredSortedNodes = (
  startPoint: Point3D,
  destinations: MapNode[],
  startAddress: string | undefined
): MapNode[] => {
  const groupedNodes = groupNodesByStorey(destinations);
  const levels = bringStartingStoreyForward(startPoint, groupedNodes);
  const arrayOfGroupedNodes = levels.reduce(
    (previous: Array<MapNode[]>, level: number): Array<MapNode[]> => {
      previous.push(groupedNodes.get(level)!);
      return previous;
    },
    []
  );

  return arrayOfGroupedNodes
    .map((nodesOnLevel: MapNode[]) => {
      // for each start from the far left (x min)
      return sortNodeOnSameLevel(nodesOnLevel);
    })
    .flat();
};
