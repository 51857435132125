const LOG_PARSER = /\[([:.\w\s]+)\]\s+LOG\s+(\{.*?\n\}(?=\n|\s*$))/gs;

function getTime(text: string) {
  const values = text.split(".");
  const date = new Date(values[0]);
  const ms = parseFloat(values[1] || "0");
  date.setMilliseconds(ms);
  return date.getTime();
}

/** Takes a log exported from the RN app and simulates the render calls with the same timing */
export default function debug(log: string) {
  const renders = [...(log?.matchAll(LOG_PARSER) ?? [])]
    .map((match) => ({
      time: getTime(match[1]),
      props: JSON.parse(match[2])
    }))
    .filter((x) => !!x.time && !!x.props);

  if (renders.length) {
    const [{ time: start }] = renders;
    for (const { time, props } of renders) {
      const delay = time - start;
      setTimeout(() => window.StoreMap?.render(props), delay);
    }
  }
}
