import React, { useState } from "react";
import { MapMode } from "../types";
import Autocomplete from "react-autocomplete";
import Pin from "./atoms/Pin";

const mockProducts = [
  {
    id: 0,
    label: "Thor Hammer 650g Nylon Soft Faced Hammer",
    address: "055.003"
  },
  {
    id: 1,
    label: "Craftright 20oz Solid Claw hammer",
    address: "055.005"
  },
  {
    id: 2,
    label: "Craftright 8oz/ 226g Steel Claw Hammer",
    address: "055.003"
  },
  {
    id: 3,
    label: "Vaughan 455g Titanium Smooth Face Hammer",
    address: "055.003"
  },
  {
    id: 4,
    label: "Fiskars 620g 22oz Framing Hammer",
    address: "055.003"
  },
  {
    id: 5,
    label: "Ozito PXC 18V Drill Driver Kit",
    address: "022.003"
  },
  {
    id: 6,
    label: "XU1 Blue 18Volt Cordless Drill Driver Kit",
    address: "022.003"
  },
  {
    id: 7,
    label: "Ryobi 18V ONE+ 2.0Ah Drill Driver Kit",
    address: "022.003"
  },
  {
    id: 8,
    label: "Ozito Home 12V Drill Driver Kit",
    address: "022.003"
  },
  {
    id: 9,
    label: "Jumbuck Portland 4 Burner BBQ",
    address: "039.005",
    showService: true
  },
  {
    id: 10,
    label: "Matador 153cm Black and Silver Conquest 4 Burner Gas BBQ",
    address: "039.005",
    showService: true
  },
  {
    id: 11,
    label: "Jumbuck Club 6 Burner BBQ",
    address: "039.005",
    showService: true
  },
  {
    id: 12,
    label: "Matador Palladium 4 Burner BBQ",
    address: "039.005",
    showService: true
  }
];

const LocatorForm = ({
  storeNumber,
  startAddress,
  location
}: {
  storeNumber?: string;
  startAddress?: string;
  location?: string;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [askService, setAskService] = useState(false);
  const [showService, setShowService] = useState(false);
  const [destination, setDestination] = useState<undefined | { name: string; item: any }>();

  const handleOnSelect = (name: string, item: any, showSOD?: boolean) => {
    setSearchValue(name);
    setAskService(!!item.showService);

    setDestination({
      name,
      item
    });

    const startPoint = startAddress ? startAddress : location;

    const locationList = [
      {
        address: item.address
      }
    ];

    if (showSOD) {
      locationList.push({
        address: "Special Orders Desk"
      });
    }

    if (startPoint) {
      window.StoreMap!.render({
        storeNumber,
        mode: MapMode.list,
        list: locationList,
        startAddress: startPoint,
        showDirections: true,
        viewportPadding: {
          top: 25,
          bottom: 112,
          left: 12,
          right: 12
        },
        selectedStorey: 1
      });
    }
  };

  let serviceContent = null;
  if (askService) {
    serviceContent = (
      <button
        className="border border-gray-300 rounded p-3 w-full font-medium"
        onClick={() => {
          setShowService(true);
          setAskService(false);
          if (destination?.name && destination?.item) {
            handleOnSelect(destination.name, destination.item, true);
          }
        }}
      >
        Need help with BBQ assembly?
      </button>
    );
  }
  if (showService) {
    serviceContent = null;
  }

  let formContent = null;
  if (showService) {
    formContent = (
      <div className="service-content">
        <div className="service-header">
          <button className="service-back" onClick={() => window.location.reload()}>
            &lt; Back
          </button>
          BBQ Assembly
        </div>
        <p className="mt-10">
          Our BBQ Assembly Service offers a convenient, easy way to get your new BBQ assembled.
        </p>
        <p className="mt-2">
          Simply fill out the form below, and we’ll be in touch soon to help you get BBQing sooner.
        </p>
        <div className="service-alert">Assembly services are a flat fee of $160.00 </div>
        <form>
          <div className="form-field">
            <input type="text" placeholder="Name" />
          </div>
          <div className="form-field">
            <input type="text" placeholder="Address" />
          </div>
          <div className="form-field">
            <input type="text" placeholder="Email Address" />
          </div>
          <div className="form-field">
            <input type="text" placeholder="Phone" />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  } else if (destination?.item) {
    formContent = (
      <div className="selected-destination">
        <div className="selected-location">
          <Pin />
          <span>Aisle {destination.item.address.split(".")[0].replace(/^0+/, "")}</span>
          <button className="selected-close" onClick={() => window.location.reload()}>
            Close
          </button>
        </div>
        <div className="selected-product">
          <div className="product-image">
            <img src="/images/product.png" />
          </div>
          <div className="product-details">
            <span>I/N:01234567</span>
            <p>{destination?.name}</p>
          </div>
        </div>
      </div>
    );
  } else {
    formContent = (
      <>
        <h2 className="mb-5 text-center text-green-800 font-bold">What are you looking for?</h2>
        <div className="locator-tabs">
          <div className="tab selected">Products</div>
          <div className="tab">Services</div>
          <div className="tab">Departments</div>
        </div>

        <div className="locator-autocomplete mt-5">
          <Autocomplete
            wrapperStyle={{
              display: "flex"
            }}
            inputProps={{
              placeholder: "Search products and more"
            }}
            getItemValue={(item) => item.label}
            shouldItemRender={(item, value) => {
              if (value) {
                return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
              }
              return false;
            }}
            items={mockProducts}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.label}
                className="px-2 py-1"
                style={{ background: isHighlighted ? "lightgray" : "white" }}
              >
                {item.label}
              </div>
            )}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSelect={handleOnSelect}
          />
        </div>
      </>
    );
  }

  return (
    <div className={`locator ${showService && "service-form"}`}>
      {formContent}
      <div className="flex justify-center mt-6">{serviceContent}</div>
      {askService && !showService && (
        <div>
          <div className="selected-location padding-bottom-10">
            <span>You might also like</span>
          </div>
          <div className="selected-destination">
            <div className="selected-product">
              <div className="product-image">
                <img src="/images/bbq1.jpeg" />
              </div>
              <div className="product-details">
                <span>I/N:4460459</span>
                <p>BBQ Buddy Large BBQ Wipes - 12 Pack</p>
                <div className="selected-location">
                  <Pin />
                  <span>Aisle 23</span>
                  <div className="price" onClick={() => window.location.reload()}>
                    $ 5.25
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="selected-destination">
            <div className="selected-product">
              <div className="product-image">
                <img src="/images/bbq2.jpeg" />
              </div>
              <div className="product-details">
                <span>I/N:3180257</span>
                <p>BBQ Buddy Flat Bamboo BBQ Skewers - 50 Pack</p>
                <div className="selected-location">
                  <Pin />
                  <span>Aisle 23</span>
                  <div className="price" onClick={() => window.location.reload()}>
                    $ 4.15
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="selected-destination">
            <div className="selected-product">
              <div className="product-image">
                <img src="/images/bbq3.jpeg" />
              </div>
              <div className="product-details">
                <span>I/N:0371615</span>
                <p>Matpro BBQ Splatter Mat</p>
                <div className="selected-location">
                  <Pin />
                  <span>Aisle 20</span>
                  <div className="price" onClick={() => window.location.reload()}>
                    $ 16.95
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="selected-destination">
            <img src="/images/promptpf.png" />
          </div>
        </div>
      )}
    </div>
  );
};

export default LocatorForm;
