import { Layer, Point, COORDINATE_SYSTEM } from "@deck.gl/core";
import { TextLayer } from "@deck.gl/layers";
import { multilinify } from "../util";
import colors from "../../constants/colors";
import { LabelMap } from "../../hooks/use-labels";
import { FONT_FAMILY } from "../../constants/style";
import { LabelLayerConfig, TextLayerConfig } from "../../types";

const STROKE_OFFSETS: Point[] = [
  [-1, -1],
  [-1, 1],
  [1, -1],
  [1, 1]
];

export function createTextLayer<T>({
  id,
  data,
  visible,
  getPosition,
  getText,
  coordinateSystem = COORDINATE_SYSTEM.CARTESIAN,
  getColor = [0, 0, 0],
  size = 8,
  lineHeight = 1.0,
  angle = 0,
  updateTriggers = {},
  stroke = false
}: TextLayerConfig<T>): Layer[] {
  const colorFormat = "RGB";

  let strokeLayers: TextLayer<T>[] = [];
  if (stroke) {
    strokeLayers = STROKE_OFFSETS.map(
      (offset, i) =>
        new TextLayer<T>({
          id: `${id} Stroke ${i + 1}`,
          coordinateSystem,
          data,
          visible,
          getPosition,
          getAngle: angle,
          getText: (item: T) => multilinify(getText(item) || ""),
          getSize: size,
          lineHeight,
          getPixelOffset: offset,
          sizeUnits: "pixels",
          fontFamily: FONT_FAMILY,
          fontWeight: "normal",
          getColor: colors.textStroke,
          colorFormat,
          pickable: false,
          updateTriggers
        })
    );
  }

  return strokeLayers.concat([
    new TextLayer<T>({
      id,
      coordinateSystem,
      data,
      visible,
      getPosition,
      getAngle: angle,
      getText: (item: T) => multilinify(getText(item) || ""),
      getSize: size,
      lineHeight,
      sizeUnits: "pixels",
      fontFamily: FONT_FAMILY,
      fontWeight: "normal",
      getColor: getColor,
      colorFormat,
      pickable: false,
      updateTriggers
    })
  ]);
}

export function createLabelLayers(source: LabelMap, zoom: number, config: LabelLayerConfig) {
  return [...source.entries()].flatMap(([minZoomLevel, labels]) =>
    createTextLayer({
      ...config,
      id: [config.id, minZoomLevel].join(":"),
      data: labels,
      visible: config.visible && zoom >= minZoomLevel
    })
  );
}
