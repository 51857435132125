import React, { ReactNode, useMemo } from "react";
import { DeckGLChildProps } from "@deck.gl/react";
import { Point } from "@deck.gl/core";
import { Styleable } from "../types";

type HorizontalPosition = "left" | "center" | "right";
type VerticalPosition = "top" | "center" | "bottom";

const TRANSLATE_X = {
  left: "",
  center: "translateX(-50%)",
  right: "translateX(-100%)"
};
const TRANSLATE_Y = {
  top: "",
  center: "translateY(-50%)",
  bottom: "translateY(-100%)"
};

export interface MapPointProps extends Styleable {
  point: Point;
  children: ReactNode;
  xPosition?: HorizontalPosition;
  yPosition?: VerticalPosition;
  onClick?: () => void;
}

function calculateTransform(
  xPosition: HorizontalPosition,
  yPosition: VerticalPosition
): string | undefined {
  const transform: string[] = [TRANSLATE_X[xPosition], TRANSLATE_Y[yPosition]];
  return transform.join(" ") ?? undefined;
}

export default function MapPoint({
  point: [x, y],
  children,
  xPosition = "center",
  yPosition = "center",
  className,
  style,
  onClick,
  ...props
}: MapPointProps) {
  const { viewport } = props as unknown as DeckGLChildProps;
  const [left, top] = useMemo(() => viewport.project([x, y]), [viewport, x, y]);
  const transform = useMemo(() => calculateTransform(xPosition, yPosition), [xPosition, yPosition]);
  if (isNaN(left) || isNaN(top)) return null;
  return (
    <div
      className={`${className ?? ""} absolute`}
      style={{ ...style, left, top, transform }}
      onClick={() => onClick?.()}
    >
      {children}
    </div>
  );
}
MapPoint.deckGLViewProps = true;
