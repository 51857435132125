import { Point2D, Point3D } from "@deck.gl/core";

import {
  Graph,
  Grouped,
  Path,
  PathSection,
  AddressableLocations,
  Polygon,
  ListItem,
  LocationDetail,
  LocationLevelAccess,
  WaypointDetail
} from "../../types";
import { index, group, capitalise } from "../util";
import { getCenter } from "../geometry/geometry";

type AddressEntry = [string, Polygon[] | undefined];

function getAddressName(address: string, includeBay = false) {
  const match = /(\d{3})(?:\.(\d{3}))/.exec(address);
  if (match) {
    const [aisle, bay] = match.slice(1).map((x) => parseInt(x, 10));
    let name = `Aisle ${aisle}`;
    if (includeBay && match[2]) {
      name += `, Bay ${bay}`;
    }
    return name;
  }
  return address;
}

function ensureUniqueNames(waypoints: WaypointDetail[]) {
  const waypointsWithDuplicateName = Object.values(
    group(
      waypoints,
      (w) => w.name,
      (w) => w
    )
  )
    .filter((x: any) => x.length > 1)
    .flat() as WaypointDetail[];
  for (const waypoint of waypointsWithDuplicateName) {
    waypoint.name = getAddressName(waypoint.address, true);
  }
}

function getFloorPath(path: Path, storey: number): PathSection[] {
  const result = path.sections.filter((p) => p.point[2] === storey);

  if (result?.length > -1) result[0].distance = 0;

  return result;
}

function getRepresentativePoint([, polygons]: AddressEntry): Point3D {
  return getCenter((polygons ?? []).flatMap((p) => p)) as Point3D;
}

function getPathDistance(paths: PathSection[]): number {
  return paths.reduce((a, b) => {
    return a + b.distance;
  }, 0);
}

function getListItemsAsGrouped(items: ListItem[], onlyUncheckedItems: boolean): Grouped<ListItem> {
  return group(
    items.filter((i) => (onlyUncheckedItems ? !i.checked : true)),
    (i) => i.address,
    (i) => i
  );
}

function getPolygonsForAddresses(
  addresses: string[],
  locations: AddressableLocations
): {
  [key: string]: Polygon[] | undefined;
} {
  return index(
    addresses,
    (a) => a,
    (a) => locations[a]
  );
}

// find all floor transitions from the currentStorey to the targetStorey
function getLevelConnectorWaypoints(
  graph: Graph,
  address: string,
  currentStorey: number,
  targetStorey: number,
  floorSegment: Path,
  polygonsByAddress: AddressableLocations
): WaypointDetail[] {
  const isTransitioningDown = currentStorey > targetStorey;
  const floorTransitionWaypoints: WaypointDetail[] = [];

  while (
    currentStorey > 0 &&
    ((!isTransitioningDown && currentStorey < targetStorey) ||
      (isTransitioningDown && currentStorey > targetStorey))
  ) {
    const isLastStoreyTransition = currentStorey === targetStorey;
    const storeyPathSection = getFloorPath(floorSegment, currentStorey);
    const levelAccessWaypoint = getLevelAccessWaypointDetail(
      storeyPathSection,
      graph,
      currentStorey,
      0,
      address,
      polygonsByAddress,
      isLastStoreyTransition,
      !isTransitioningDown
    );

    // data must be corrupt so exit and don't add
    if (!levelAccessWaypoint) return [];

    floorTransitionWaypoints.push(levelAccessWaypoint);

    if (!isTransitioningDown) {
      currentStorey++;
    } else {
      currentStorey--;
    }
  }

  return floorTransitionWaypoints;
}

function getLevelAccessWaypointDetail(
  pathSections: PathSection[],
  graph: Graph,
  storey: number,
  nodeNumber: number,
  address: string,
  polygonsByAddress: any,
  isLastStoreyTransition: boolean,
  isGoingUp: boolean
): WaypointDetail | undefined {
  const previousLevelAccessPathSection =
    pathSections[isLastStoreyTransition ? 0 : pathSections.length - 1];
  const levelAccessPreviousFloorDistance = getPathDistance(pathSections);
  const levelAccessPoint = previousLevelAccessPathSection?.point;

  if (levelAccessPoint) {
    const levelAccessAddress = graph.findNearestNodeAddress(levelAccessPoint);
    if (levelAccessAddress) {
      const levelAccessName = getLevelAccessName(levelAccessAddress);

      //get level access key. polygonsByAddress has prefix in their keys so we need to use includes
      const polygonsByAddressKeys = Object.keys(polygonsByAddress);
      const levelAccessKey = polygonsByAddressKeys.find((x) =>
        x.toLowerCase().includes(levelAccessAddress)
      );

      return getWaypointDetail(
        nodeNumber,
        {
          cost: isLastStoreyTransition ? 0 : levelAccessPreviousFloorDistance,
          distance: isLastStoreyTransition ? 0 : levelAccessPreviousFloorDistance,
          sections: isLastStoreyTransition ? [pathSections[0]] : pathSections
        },
        storey,
        levelAccessAddress,
        getLevelAccessName(levelAccessAddress),
        [],
        levelAccessPoint,
        polygonsByAddress[levelAccessKey || address]!,
        {
          direction: isGoingUp ? "up" : "down",
          accessType: levelAccessName,
          storey: isGoingUp ? levelAccessPoint[2] + 1 : levelAccessPoint[2] - 1
        }
      );
    }
  }

  return undefined;
}

function getWaypointDetail(
  number: number,
  path: Path,
  storey: number,
  address: string,
  name: string,
  items: ListItem[],
  point: Point2D,
  polygons: Polygon[],
  levelAccess?: LocationLevelAccess
): WaypointDetail {
  const result: WaypointDetail = {
    number,
    address,
    name: name,
    point,
    items: items,
    polygons: polygons,
    path: path,
    storey: storey
  };

  if (levelAccess) result.levelAccess = levelAccess;

  return result;
}

function getLevelAccessName(address: string): string {
  return capitalise(address.split("-")[0]);
}

export function planRoute(
  items: ListItem[],
  locations: AddressableLocations,
  startAddress: string | undefined,
  startPoint: Point3D | undefined,
  graph?: Graph
): [WaypointDetail[], LocationDetail[]] {
  // TODO: This whole approach needs a rethink,
  // as there's some very tight coupling to the wayfinding graph,
  // and in some instances it's not necessary (e.g. single location "wayfinding")
  if (!items.length) {
    return [[], []];
  }

  const uncheckedItemsByAddress = getListItemsAsGrouped(items, true);
  const uniqueAddresses = Object.keys(uncheckedItemsByAddress);
  const polygonsByAddress = getPolygonsForAddresses(uniqueAddresses, locations);
  const pointsByAddress = index(
    Object.entries(polygonsByAddress).filter(([, polygons]) => polygons),
    (a) => a[0],
    // when we have nullnodes and more than 1 polugon per address it will
    // find the center point between those polygons so grab the first if more than 1
    (a) =>
      a[1] && a[1].length > 1
        ? getRepresentativePoint([a[0], [a[1]![0]]])
        : getRepresentativePoint(a)
  );

  const pointCount = Object.keys(pointsByAddress).length;

  if (!graph) {
    // TODO is there a possibility of having pointCount = 0?
    if (pointCount !== 1) return [[], []];

    const [address, point] = Object.entries(pointsByAddress)[0];
    const data = getWaypointDetail(
      1,
      { sections: [], cost: 0, distance: 0 },
      1,
      address,
      getAddressName(address),
      uncheckedItemsByAddress[address],
      point,
      polygonsByAddress[address]!
    );

    return [[data], []];
  }

  const waypoints: WaypointDetail[] = [];

  if (pointCount > 0) {
    const toolshop = (locations["department:Tool Shop"] || [undefined])[0];
    const route = graph.optimizeRoute(startAddress, startPoint, pointsByAddress, toolshop);
    // find the centerNode for an address
    startAddress = startAddress ? startAddress : route.nodes[0][1][0];
    const startNode = getClosestNode(startAddress, items, false, graph, locations);

    let number = 0;
    let floorTransitioned = false;
    let currentStorey = startNode![2];

    for (let i = 0; i < route.nodes.length; i++) {
      // point is the destination point for this node
      const [point, addresses] = route.nodes[i];
      const floorSegment = route.segments[i];
      const address = addresses[0];
      const destinationStorey = point[2];
      let floorTransitionWaypoints: WaypointDetail[] = [];
      // sections contain the path of points to the destination. first section will contain the start point
      // get storey from the first section in the floor segement for the given node
      currentStorey = floorSegment.sections[0].point[2];
      floorTransitioned = currentStorey > 0 && currentStorey !== destinationStorey;
      // floor transition, so find the connectors between the floors
      if (floorTransitioned) {
        floorTransitionWaypoints = getLevelConnectorWaypoints(
          graph,
          address,
          currentStorey,
          destinationStorey,
          floorSegment,
          locations
        );
        if (floorTransitionWaypoints.length === 0) continue;
        for (const [, floorTransition] of floorTransitionWaypoints.entries()) {
          waypoints.push(floorTransition);
        }

        // get new path to destination by removing other floor paths from the segment (eg. transition from floor 1 to 2, remove paths for floor 1)
        const nextStoreyPathSection = getFloorPath(floorSegment, destinationStorey);
        const newFloorDistance = getPathDistance(nextStoreyPathSection);

        // add node to waypoint
        waypoints.push(
          getWaypointDetail(
            ++number,
            {
              cost: newFloorDistance,
              distance: newFloorDistance,
              sections: nextStoreyPathSection
            },
            destinationStorey,
            address,
            getAddressName(address),
            uncheckedItemsByAddress[address],
            point,
            polygonsByAddress[address]!
          )
        );
      } else {
        // non floor transition node (eg. node on same floor as previous)
        const nextWaypoint = getWaypointDetail(
          ++number,
          floorSegment,
          destinationStorey,
          address,
          getAddressName(address),
          uncheckedItemsByAddress[address],
          point,
          polygonsByAddress[address]!
        );
        waypoints.push(nextWaypoint);
      }
    }
  }

  ensureUniqueNames(waypoints);

  const checkedItems = items.filter((i) => i.checked);
  const checkedItemsByAddress = group(
    checkedItems,
    (i) => i.address,
    (i) => i
  );

  const visitedLocations: LocationDetail[] = Object.entries(checkedItemsByAddress)
    .filter(([address]) => locations[address])
    .map(([address, items]) => {
      const polygons = locations[address]!;
      return {
        address,
        name: getAddressName(address),
        items,
        point: graph.findClosestNode(address, getRepresentativePoint([address, polygons]))!,
        polygons: polygons
      };
    }) as LocationDetail[];

  return [waypoints, visitedLocations];
}

// when we have a null node, wayfinding can still return a node as findClosetNode will
// check nearestNode, then node, then closest node to a point
// To fix we either change wayfinding to not use findClosetNode (the closest node to a point to a point)
// or we change this logic to get a point for a given an address and then use findClosestNode instead
// of findNearestNodeByAddress so it will find that node
export function getClosestNode(
  address: string,
  items: ListItem[],
  onlyUncheckedItems: boolean,
  graph: Graph,
  locations: AddressableLocations
): Point3D | undefined {
  const startCenterNode = getStartCenterNode(address, items, onlyUncheckedItems, graph, locations);

  return graph.findClosestNode(address, startCenterNode);
}

// finds the center of a node for a floor
export function getStartCenterNode(
  address: string,
  items: ListItem[],
  onlyUncheckedItems: boolean,
  graph: Graph,
  locations: AddressableLocations
): Point3D {
  const allItemsByAddress = getListItemsAsGrouped(items, onlyUncheckedItems);
  const allUniqueAddresses = Object.keys(allItemsByAddress);
  const allPolygonsByAddress = getPolygonsForAddresses(allUniqueAddresses, locations);
  const startAddressPolygon = allPolygonsByAddress[address];
  return getRepresentativePoint([address, startAddressPolygon]);
}

// return waypoints that belong to a storey and are adjacent
export function consecutiveStoreyWaypoints(
  storey: number,
  waypoints?: WaypointDetail[]
): WaypointDetail[] {
  if (!waypoints) return [];
  let previousIndex = -1;

  return waypoints.filter((w, index) => {
    if (w.storey === storey && (previousIndex === -1 || previousIndex + 1 === index)) {
      previousIndex = index;
      return true;
    } else if (w.storey === storey && previousIndex > 0) {
      return false;
    }

    return false;
  });
}
