import React from "react";
import ReactDOM from "react-dom";
import "./tailwind.css";
import * as serviceWorker from "./serviceWorker";
import { raiseMapEvent } from "./services";
import deepEqual from "fast-deep-equal";
import debug from "./utils/debug";
import App from "./components/App";
import { StoreMapProps, MapEventName, MapMode } from "./types";

document.body.addEventListener("touchstart", () => {}, false);

let prev: StoreMapProps | undefined;
let timeout: number | undefined;
const DEBOUNCE_MS = 50; // This debounce reduces churn on updates

window.StoreMap = {
  render(props: StoreMapProps) {
    if (prev) {
      if (!props.selectedStorey && prev.selectedStorey) {
        props.selectedStorey = prev.selectedStorey;
      }
      if (deepEqual(prev.list, props.list)) {
        props.list = prev.list;
      }
      if (deepEqual(prev.viewportPadding, props.viewportPadding)) {
        props.viewportPadding = prev.viewportPadding;
      }
    }
    prev = props;

    if (timeout) {
      clearTimeout(timeout);
    }

    function renderCallback() {
      ReactDOM.render(<App {...props} />, document.getElementById("root"));
      timeout = undefined;
    }
    timeout = window.setTimeout(renderCallback, DEBOUNCE_MS);
  },
  debug
};

raiseMapEvent(MapEventName.ready);

const queryParams = new URLSearchParams(window.location.search);
const startAddress = queryParams.get("start");
const storeId = queryParams.get("store");
if (startAddress && storeId) {
  window.StoreMap.render({
    storeNumber: storeId,
    mode: MapMode.location,
    location: decodeURI(startAddress),
    focusAddress: decodeURI(startAddress),
    viewportPadding: {
      top: 25,
      bottom: 112,
      left: 12,
      right: 12
    },
    selectedStorey: 1,
    showCurrentPosition: true
  });
}

window.addEventListener(
  "message",
  (event) => {
    if (event.origin === window.origin) return;

    window.ParentWindow = event?.source as Window;
    window.StoreMap?.render(event.data);
  },
  false
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
