import axios from "axios";
import Cache from "../utils/cache";
import { GraphImpl } from "../services";
import { EdgeTransport, Graph, GraphProperties, LegacyEdgeTransport, LegacyNearestNodeTransport, NearestNodeTransport } from "../types";
import { DAY } from "../constants/util";

const api = axios.create({
  baseURL: `/api`
});

const cache = new Cache("wayfinding-graphs", DAY);

export async function getWayfindingGraph(
  storeNumber: string,
  mapId: string
): Promise<Graph | undefined> {
  const cacheKey = `${storeNumber}/${mapId}-v3`;

  const data = await cache.get<GraphProperties>(cacheKey);
  if (data) {
    const graphFromCache = GraphImpl.fromCacheData(data);
    if (graphFromCache) return graphFromCache;
  }

  console.time("Generate graph");
  const [edgeResponse, nearestResponse] = await Promise.all([
    api.get<EdgeTransport | LegacyEdgeTransport>(`/${storeNumber}/${mapId}-edges.json`),
    api.get<NearestNodeTransport | LegacyNearestNodeTransport>(
      `/${storeNumber}/${mapId}-nodes.json`
    )
  ]).catch(() => []);
  if (!edgeResponse || !nearestResponse) return;

  let edgeTransport: EdgeTransport;
  let nearestNodeTransport: NearestNodeTransport;

  const edgeData = edgeResponse.data;
  const nearestData = nearestResponse.data;

  // Below is mapping old structure into the new structure
  // TODO: Delete this once all maps are published into the new format
  if ("storeys" in edgeData) {
    edgeTransport = edgeData;
  } else {
    edgeTransport = {
      resolution: edgeData.resolution,
      storeys: [
        {
          storey: 1,
          data: edgeData.data
        }
      ],
      levelAccesses: []
    };
  }
  if ("storeys" in nearestData) {
    nearestNodeTransport = nearestData;
  } else {
    nearestNodeTransport = {
      resolution: nearestData.resolution,
      storeys: [
        {
          storey: 1,
          data: nearestData.data
        }
      ]
    };
  }

  const graph = new GraphImpl(edgeTransport, nearestNodeTransport);

  cache.set(cacheKey, graph.cacheData);
  console.timeEnd("Generate graph");
  return graph;
}

