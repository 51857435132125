import React, { useMemo } from "react";
import { Point3D } from "@deck.gl/core";

import { getCenter, planRoute, raiseWaypointUpdateEvent } from "../services";
import Map from "./Map";
import LocatorForm from "./LocatorForm";

import { raiseStoreyChangedEvent } from "../services";
import { useElementSize } from "../hooks/use-element-size";
import { getClosestNode, getStartCenterNode, consecutiveStoreyWaypoints } from "../services";
import { useStore } from "../hooks/use-store";
import { useDirections } from "../hooks/use-directions";
import { SelectedElement, useSelectedElements } from "../hooks/use-selected-elements";
import { useLabels } from "../hooks/use-labels";
import { StoreMapProps, MapMode, WaypointDetail, ListItem } from "../types";
import { useFocusAddress, useList, useLocation } from "../hooks/use-list";
import Alert from "./atoms/Alert";

export default function App({
  storeNumber,
  mode,
  showDirections,
  selectedStorey,
  ...props
}: StoreMapProps) {
  const defaultStorey = 1;

  // Older versions of the app can't support multistorey as the storey/floor selector is in the PF app
  // selectedStorey prop is used to determine
  // If a request comes in to a multistorey storey via the older apps we wu
  const supportMultiStorey = selectedStorey !== undefined;

  let currentStorey = selectedStorey;
  const store = useStore(storeNumber, supportMultiStorey);
  const [size, setSize] = useElementSize();
  const list = useList(props.list, store);
  const focusAddress = useFocusAddress(props.focusAddress, store);
  const startAddress = useFocusAddress(props.startAddress, store);
  const location = useLocation(props.location, store);

  const selectedElements = useSelectedElements(store, mode, location, list, selectedStorey);
  let userSwitchedAddress = false;
  const startPoint = useMemo(() => {
    if (startAddress && store && store.wayfindingGraph) {
      const startLocation = store.locations[startAddress];
      if (startLocation) {
        const point = store.wayfindingGraph.findClosestNode(
          startAddress,
          getCenter(startLocation.flat()) as Point3D
        );

        if (focusAddress) {
          const locationAddress = getClosestNode(
            focusAddress,
            [{ address: focusAddress, itemNumber: "", checked: false }],
            false,
            store.wayfindingGraph!,
            store.locations
          );

          const focusPointOnCurrentFloor = (locationAddress &&
            locationAddress[2] &&
            locationAddress[2] === currentStorey) as boolean;

          if (focusPointOnCurrentFloor) {
            return point;
          }
        }

        if (point && point[2] && point[2] !== currentStorey) {
          userSwitchedAddress = true;
          currentStorey = point[2];
          raiseStoreyChangedEvent(currentStorey!);
        }

        return point;
      }

      return undefined;
    }
  }, [store, startAddress]);

  // recalculate wayfinding data when location, list, or startAddress changes
  const [waypoints, visitedLocations] = useMemo(() => {
    if (store && mode !== MapMode.store) {
      const items: ListItem[] = [];
      if (mode === MapMode.list) {
        items.push(...(list ?? []));
      } else if (location) {
        items.push({ address: location, itemNumber: "", checked: false });
      }

      if (items.length) {
        const waypointData = planRoute(
          items,
          store.locations,
          startAddress,
          startPoint,
          store.wayfindingGraph
        );

        waypointData[1]?.sort((a, b) => {
          if (a?.point[2] && b?.point[2]) return a.point[2] - b.point[2];
          return 0;
        });
        // assign storey name when there is a floor change
        waypointData[0].forEach((w) => {
          if (w.levelAccess) {
            w.levelAccess.storeyName =
              store.storeys.find((s) => s.storeyNumber === w.storey)?.name ?? "";
          }
        });

        // detect floor change when in list mode
        if (mode === MapMode.list && startAddress) {
          const address = userSwitchedAddress
            ? startAddress
            : focusAddress
            ? focusAddress
            : startAddress;
          const locationAddress = getClosestNode(
            address,
            [{ address, itemNumber: "", checked: false }],
            false,
            store.wayfindingGraph!,
            store.locations
          );

          if (locationAddress && locationAddress[2] !== currentStorey) {
            currentStorey = locationAddress[2];
            raiseStoreyChangedEvent(currentStorey);
          }
        } // detect floor change when in location mode
        else if (mode === MapMode.location && location) {
          const address = startAddress ? startAddress : location;
          const locationAddress = getClosestNode(
            address,
            [{ address, itemNumber: "", checked: false }],
            false,
            store.wayfindingGraph!,
            store.locations
          );
          if (locationAddress && locationAddress[2] !== currentStorey) {
            // floor change
            currentStorey = locationAddress[2];
            raiseStoreyChangedEvent(currentStorey);
          }
        }

        raiseWaypointUpdateEvent(mode, [waypointData[0], waypointData[1]]);
        return waypointData;
      }
    } // detect floor change when in store mode
    else if (store && mode === MapMode.store && location) {
      // we are looking for services for a given floor so use getStartCenterNode
      // getClosestNode will return the distance if the point is not found and doesn't take into account the storey.
      const locationAddress = getStartCenterNode(
        location,
        [{ address: location, itemNumber: "", checked: false }],
        false,
        store.wayfindingGraph!,
        store.locations
      );
      if (locationAddress && locationAddress[2] !== currentStorey) {
        // floor change
        currentStorey = locationAddress[2];
        raiseStoreyChangedEvent(currentStorey);
      }
    }

    raiseWaypointUpdateEvent(mode, [[], []]);
    return [undefined, []];
  }, [store, mode, location, list, startAddress, startPoint]);

  // Detect floor change when in list mode and focusAddress change
  // This happens when swiping in the carousel instead of checking an item
  useMemo(() => {
    if (store && focusAddress && (mode === MapMode.list || mode === MapMode.location)) {
      const address = focusAddress;
      const locationAddress = getClosestNode(
        address,
        [{ address, itemNumber: "", checked: false }],
        false,
        store.wayfindingGraph!,
        store.locations
      );

      if (locationAddress && locationAddress[2] !== currentStorey) {
        currentStorey = locationAddress[2];
        raiseStoreyChangedEvent(currentStorey);
      }
    }
  }, [focusAddress]);

  let waypointStorey: WaypointDetail[] = [];
  let filteredSelectedElements: SelectedElement[] = [];

  if (!currentStorey) currentStorey = defaultStorey;
  // find the storey when interacting via a store and location is passed
  if (mode === MapMode.store) {
    if (location) {
      filteredSelectedElements = selectedElements;
    }
  } else {
    waypointStorey = consecutiveStoreyWaypoints(currentStorey, waypoints); // waypoints?.filter((w,) => w.storey === currentStorey) ?? []
    filteredSelectedElements = selectedElements.filter(
      (s) => waypointStorey.find((f) => f.address === s.address) !== undefined
    );
  }

  // find first level transition to determine whether we are going up or down
  const nextLevelAccessDirection =
    waypointStorey.find((w) => w.levelAccess)?.levelAccess?.direction ?? undefined;
  const storey = store?.storeys.find((s: any) => s.storeyNumber === currentStorey);
  const showLandmarkPins = mode === MapMode.store;
  const directions = useDirections(waypointStorey);
  const outline = useMemo(() => (storey ? [storey?.outline] : []), [storey]);
  const aisleNumberLabels = useLabels(storey?.aisles, -8);
  // TODO pull these zoom level out from a constants
  const departmentLabels = useLabels(storey?.departmentLabels, -7.5, {
    toolshop: -Infinity,
    "tool shop": -Infinity,
    nursery: -Infinity,
    "green life": -Infinity,
    greenlife: -Infinity,
    paint: -Infinity,
    timber: -Infinity,
    "timber yard": -Infinity,
    "timber & trade sales": -Infinity,
    electrical: -Infinity,
    plumbing: -8.5,
    leisure: -8.5,
    flooring: -8.5,
    cleaning: -8.5,
    fixings: -8.5,
    "garden tools": -8.5,
    "garden care": -8.5,
    doors: -8.5
  });
  const landmarkLabels = useLabels(storey?.landmarks, -7.5, {
    "special orders desk": -Infinity,
    "trade desk": -Infinity,
    toilets: -Infinity,
    "paint desk": -8.5,
    "information desk": -8.5,
    cafe: -8.5,
    "hardware cafe": -8.5,
    registers: -8
  });
  const entranceLabels = useLabels(storey?.entrances);
  const levelAccessLabels = useLabels(
    storey?.levelAccess.map((l: any) => {
      l[0] = l[0].split("-")[0];
      return l;
    })
  );

  showDirections = !!showDirections && !!startAddress;

  const queryParams = new URLSearchParams(window.location.search);
  const hasStoreId = queryParams.get("store") !== null;

  return (
    <div className="flex flex-col items-stretch h-screen relative select-none">
      <div className="locator-banner">
        <img src="/images/bunnings-logo.png" />
      </div>
      <div className="flex flex-1" ref={setSize}>
        {size && store ? (
          <Map
            key={store.storeNumber}
            {...{
              store,
              selectedStorey: currentStorey!,
              userSelectedStorey: selectedStorey,
              mode,
              size,
              selectedElements: filteredSelectedElements,
              startAddress,
              startPoint: startPoint && startPoint[2] === currentStorey! ? startPoint : undefined,
              outline,
              waypoints: waypoints ?? [],
              visitedLocations,
              showLandmarkPins,
              showDirections,
              directions,
              aisleNumberLabels,
              departmentLabels,
              landmarkLabels,
              entranceLabels,
              levelAccessLabels,
              nextLevelAccessDirection,
              ...props,
              focusAddress
            }}
          />
        ) : null}
      </div>
      {hasStoreId && (
        <LocatorForm
          storeNumber={store?.storeNumber}
          startAddress={startAddress}
          location={location}
        />
      )}
    </div>
  );
}
