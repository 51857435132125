import { Point } from "@deck.gl/core";
import { NamedPoint, NamedPolygon, Polygon, Rectangle, Size, Vector } from "../../types";


export function isEmptySize({ width, height }: Size) {
  return !width && !height;
}

export function getCenter(source: Point[]): Point {
  const { x, y, width, height } = getBoundingRectangle(source);
  return [
    Math.round(x + width / 2),
    Math.round(y + height / 2),
    Math.min(...source.map((p) => p[2]))
  ];
}

export function getBoundingRectangle(points: Point[]): Rectangle {
  if (!points.length) return { x: 0, y: 0, width: 0, height: 0 };
  const [x, y, maxX, maxY] = points.reduce(
    ([minX, minY, maxX, maxY], [x, y]) => [
      Math.min(minX, x),
      Math.min(minY, y),
      Math.max(maxX, x),
      Math.max(maxY, y)
    ],
    [Infinity, Infinity, -Infinity, -Infinity]
  );
  const [width, height] = [maxX - x, maxY - y];
  return {
    x: Math.round(x),
    y: Math.round(y),
    width: Math.round(width),
    height: Math.round(height)
  };
}

export function getPolygon({ x, y, width, height }: Rectangle): Point[] {
  return [
    [x, y],
    [x + width, y],
    [x + width, y + height],
    [x, y + height],
    [x, y]
  ];
}

export function getVector(start: Point, end: Point): Vector {
  const [sx, sy] = start;
  const [ex, ey] = end;
  return {
    dx: ex - sx,
    dy: ey - sy
  };
}

export function calclulateDistance(dx: number, dy: number) {
  return Math.sqrt(Math.pow(Math.abs(dx), 2) + Math.pow(Math.abs(dy), 2));
}

export enum PointInPolygonResult {
  inside = -1,
  onBoundary = 0,
  outside = 1
}

// TODO: delete once all legacy maps have been published
export function applyStoreyToPolygon(polygon: Polygon, storeyNumber: number): Polygon {
  return polygon.map(([x, y]) => [x, y, storeyNumber]);
}

// TODO: delete once all legacy maps have been published
export function applyStoreyToNamedPolygons(
  polygons: NamedPolygon[],
  storeyNumber: number
): NamedPolygon[] {
  return polygons.map(([name, polygon, area]) => [
    name,
    applyStoreyToPolygon(polygon, storeyNumber),
    area
  ]);
}

// TODO: delete once all legacy maps have been published
export function applyStoreyToNamedPoint(points: NamedPoint[], storeyNumber: number): NamedPoint[] {
  return points.map(([name, point]) => [name, [point[0], point[1], storeyNumber]]);
}
