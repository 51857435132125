import React from "react";
import { Styleable } from "../../types";

interface StartPinProps extends Styleable {
  width?: number;
  height?: number;
  tracking?: boolean;
}

export default function StartPin({
  width = 12,
  height = (width / 19) * 42,
  tracking,
  ...props
}: StartPinProps) {
  return tracking ? (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_51_92194)">
        <circle
          cx="11.6896"
          cy="11.8361"
          r="11.1896"
          fill="#00A3FF"
          fillOpacity="0.3"
          stroke="#00A3FF"
        />
        <circle cx="11.6895" cy="11.8359" r="4.8877" fill="#00A3FF" />
      </g>
      <defs>
        <clipPath id="clip0_51_92194">
          <rect width="23.3793" height="23.3793" fill="white" transform="translate(0 0.146484)" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.4974 36.75C12.7881 36.75 15.4557 37.4775 15.4557 38.375C15.4557 39.2725 12.7881 40 9.4974 40C6.2067 40 3.53906 39.2725 3.53906 38.375C3.53906 37.4775 6.2067 36.75 9.4974 36.75Z"
          fill="white"
          fillOpacity="0.01"
        />
      </g>
      <mask
        id="path-2-outside-1"
        maskUnits="userSpaceOnUse"
        x="6.87109"
        y="13.0001"
        width="6"
        height="25"
        fill="black"
      >
        <rect fill="white" x="6.87109" y="13.0001" width="6" height="25" />
        <path d="M7.87109 14.0001H11.1211V36.7501H7.87109V14.0001Z" />
      </mask>
      <path d="M7.87109 14.0001H11.1211V36.7501H7.87109V14.0001Z" fill="#0D5257" />
      <path
        d="M7.87109 14.0001V13.0001H6.87109V14.0001H7.87109ZM11.1211 14.0001H12.1211V13.0001H11.1211V14.0001ZM11.1211 36.7501V37.7501H12.1211V36.7501H11.1211ZM7.87109 36.7501H6.87109V37.7501H7.87109V36.7501ZM7.87109 15.0001H11.1211V13.0001H7.87109V15.0001ZM10.1211 14.0001V36.7501H12.1211V14.0001H10.1211ZM11.1211 35.7501H7.87109V37.7501H11.1211V35.7501ZM8.87109 36.7501V14.0001H6.87109V36.7501H8.87109Z"
        fill="white"
        mask="url(#path-2-outside-1)"
      />
      <circle cx="9.49805" cy="9.125" r="8.625" fill="#0D5257" stroke="white" />
      <defs>
        <filter
          id="filter0_d"
          x="1.53906"
          y="34.75"
          width="15.9167"
          height="7.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
