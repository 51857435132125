import { getMap, getAddressableLocations } from "./map";
import { getWayfindingGraph } from "./wayfinding";
import { getServices } from "./services";
import { getMapId } from "./stores-manifest";
import { StoreData } from "../types";

export async function getStore(storeNumber: string): Promise<StoreData | undefined> {
  const mapId = await getMapId(storeNumber);
  if (!mapId) return;

  const [map, wayfindingGraph, services] = await Promise.all([
    getMap(storeNumber, mapId),
    getWayfindingGraph(storeNumber, mapId),
    getServices(storeNumber, mapId)
  ]);
  const [locations, defaultLandmarkAddress] = getAddressableLocations(map);
  return {
    ...map,
    locations,
    wayfindingGraph,
    services,
    defaultLandmarkAddress
  };
}

export default { getStore };
