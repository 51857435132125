import { applyStoreyToPolygon, applyStoreyToNamedPolygons, applyStoreyToNamedPoint, getCenter } from "..";
import { Bay, MapData, NamedPolygon, NamedPoint } from "../../types";

// TODO: delete once all legacy maps have been published
export function applyStoreyToBays(bays: Bay[], storeyNumber: number): Bay[] {
  return bays.map(([aisle, bay, polygon, area, name]) => [
    aisle,
    bay,
    applyStoreyToPolygon(polygon, storeyNumber),
    area,
    name
  ]);
}

// TODO: delete once all legacy maps have been published
// apply storeyNumber to geometry properties for each storey/floor
export function applyStorey(map: MapData): MapData {
  for (const storey of map.storeys) {
    const storeyNumber = storey.storeyNumber;

    storey.outline = applyStoreyToPolygon(storey.outline, storeyNumber);
    storey.landmarks = applyStoreyToNamedPolygons(storey.landmarks, storeyNumber);
    storey.departments = applyStoreyToNamedPolygons(storey.departments, storeyNumber);
    storey.bays = applyStoreyToBays(storey.bays, storeyNumber);
    storey.promoBays = applyStoreyToBays(storey.promoBays, storeyNumber);
    storey.aisles = applyStoreyToNamedPoint(storey.aisles, storeyNumber);
    storey.departmentLabels = applyStoreyToNamedPoint(storey.departmentLabels, storeyNumber);
    storey.pointsOfInterest = applyStoreyToNamedPoint(storey.pointsOfInterest, storeyNumber);
    storey.entrances = applyStoreyToNamedPoint(storey.entrances, storeyNumber);
    storey.levelAccess = applyStoreyToNamedPolygons(storey.levelAccess, storeyNumber);
    storey.center = [storey.center[0], storey.center[1], storeyNumber];
  }

  return map;
}

export function fixMapInconsistencies(_storeNumber: string, map: MapData): MapData {
  for (const storey of map.storeys) {
    for (const landmark of storey.landmarks) {
      const [name] = landmark;
      landmark[0] = name.replace("  ", " ");
    }
    const landmarkNames = new Set(storey.landmarks.map((l) => l[0]));
    const isValidDepartment = ([name]: NamedPolygon | NamedPoint) =>
      !/registers|unlabelled|special orders|playground|cafe|promo|service area/i.test(name) &&
      !landmarkNames.has(name);

    storey.departments = storey.departments.filter(isValidDepartment);
    storey.departmentLabels = storey.departmentLabels.filter(isValidDepartment);
    if (!storey.entrances?.length) {
      storey.entrances = storey.pointsOfInterest
        .filter(([name]) => /entrance/i.test(name))
        .concat(
          storey.landmarks
            .filter(([name]) => /entrance/i.test(name))
            .map(([name, polygon]) => [name, getCenter(polygon)])
        );
      storey.pointsOfInterest = storey.pointsOfInterest.filter(
        (poi) => !storey.entrances.includes(poi)
      );
      storey.landmarks = storey.landmarks.filter(([name]) => !/entrance/i.test(name));
    }
  }

  return map;
}

//nameWithStorey is formated as "Specia Order Desk_3" and we want to extract the first part only
export function getDisplayName(nameWithStorey: string){
  return nameWithStorey.split('_')[0];
}
