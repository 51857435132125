import axios from "axios";
import { StoreManifest, StoresManifest } from "../types";

const api = axios.create({
  baseURL: "/api"
});


async function getStoreManifest(storeNumber: string): Promise<StoreManifest | undefined> {
  const {
    data: { stores }
  } = await api.get<StoresManifest>("stores-manifest.json");

  return stores[storeNumber];
}

export async function getMapId(storeNumber: string) {
  const manifest = await getStoreManifest(storeNumber);
  return manifest?.mapId;
}
