import { Counts, Grouped, Weighted } from "../../types";

const BASE_TITLE = document.title;

export function setTitle(title?: string) {
  document.title = title ? `${title} - ${BASE_TITLE}` : BASE_TITLE;
}

export function isTruthy<T>(value: T | null | undefined | false | ""): value is T {
  return !!value;
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value != null;
}

export function clamp(value: number, min = -Infinity, max = Infinity) {
  return Math.min(Math.max(value, min), max);
}

export function distinct<T>(values: (T | undefined)[]): T[] {
  return [...new Set(values.filter((v) => v !== undefined) as T[])];
}

export function multilinify(term: string | undefined) {
  return term ? term.replace("&", "and").split(" ").join("\n") : "";
}

export function group<T, TValue>(
  items: T[],
  keySelector: (item: T) => string,
  valueSelector: (item: T) => TValue
): Grouped<TValue> {
  return items.reduce((groups, item) => {
    const key = keySelector(item);
    const group = groups[key] || [];
    group.push(valueSelector(item));
    groups[key] = group;
    return groups;
  }, {} as Grouped<TValue>);
}

export function count<T, TKey = T>(items: T[], keySelector: (item: T) => TKey): Counts<TKey> {
  const counts = new Map<TKey, number>();
  for (const item of items) {
    const key = keySelector(item);
    const count = counts.get(key) ?? 0;
    counts.set(key, count + 1);
  }
  return counts;
}

export function index<T, TValue = T>(
  items: T[],
  keySelector: (item: T) => string,
  valueSelector?: (item: T) => TValue
): { [key: string]: TValue } {
  if (!valueSelector) {
    valueSelector = (i) => i as any as TValue;
  }
  return Object.fromEntries<TValue>(
    items
      .map((i) => [keySelector(i), valueSelector!(i)] as [string, TValue])
      .filter((i) => i[0] !== undefined)
  );
}

export function minBy<T>(items: T[], weightSelector: (item: T) => number): T | undefined {
  return items.reduce<Weighted<T | undefined>>(
    (previous, current) => {
      const weight = weightSelector(current);
      return weight < previous[1] ? [current, weight] : previous;
    },
    [undefined, Infinity]
  )[0];
}

export function sum<T>(items: T[], valueSelector?: (item: T) => number) {
  if (!valueSelector) {
    valueSelector = Number;
  }
  return items.reduce((previous, current) => previous + valueSelector!(current), 0);
}

export function* combinations<T>(items: T[]) {
  for (let i = 0; i < items.length; i++) {
    for (let j = i + 1; j < items.length; j++) {
      yield [items[i], items[j]];
    }
  }
}

export function indexOfEnd(str: string, value: string): number {
  const index = str.indexOf(value);

  return index === -1 ? -1 : index + value.length;
}

export function capitalise(str: string): string {
  return str && str[0].toUpperCase() + str.slice(1);
}
// in place change
export function rotateArray<T>(array: Array<T>, count: number) {
  array.unshift(...array.splice(count, array.length));
}
