import React, { useState, useEffect } from "react";

import {
  OrthographicView,
  OrthographicViewState,
  ViewStateChangeCallback,
  COORDINATE_SYSTEM,
  Point
} from "@deck.gl/core";
import { PolygonLayer, PathLayer } from "@deck.gl/layers";

import DeckGL from "@deck.gl/react";
import {
  AnimateViewStateConfig,
  Bay,
  Dictionary,
  LocationDetail,
  MapEventName,
  MapMode,
  NamedPolygon,
  Padding,
  Polygon,
  Size,
  WaypointDetail
} from "../types";

import colors, { rgbToHex, hexColors } from "../constants/colors";
import {
  createLabelLayers,
  raiseMapEvent,
  raiseWaypointFocusEvent,
  raiseStartAddressFocusEvent,
  checkLabelEligibility,
  getBoundingRectangle,
  ProjectedLabel,
  VisibilityProps,
  adjustPoint,
  animateViewState,
  calculateViewportOffset,
  clampTarget,
  initViewState
} from "../services";
import LandmarkPins from "./LandmarkPins";
import MapPoint from "./MapPoint";
import Pin from "./atoms/Pin";
import StartPin from "./atoms/StartPin";
import { Directions } from "../hooks/use-directions";
import VisitedPin from "./atoms/VisitedPin";
import StoreyPin from "./atoms/StoreyPin";
import { SelectedElement } from "../hooks/use-selected-elements";
import { LabelMap } from "../hooks/use-labels";
import MapElementLabels from "./MapElementLabels";
import { StoreData } from "../types";
import LoadingIndicator from "./atoms/LoadingIndicator";
import PositionIndicator from "./PositionIndicator";

interface Props {
  store: StoreData;
  selectedStorey: number;
  userSelectedStorey?: number;
  mode: MapMode;
  size: Size;
  viewportPadding?: Padding;
  startAddress?: string;
  startPoint?: Point;
  outline: Polygon[];
  waypoints: WaypointDetail[];
  visitedLocations: LocationDetail[];
  selectedElements: SelectedElement[];
  showDirections: boolean;
  directions: Directions;
  showLandmarkPins: boolean;
  aisleNumberLabels: LabelMap;
  departmentLabels: LabelMap;
  landmarkLabels: LabelMap;
  entranceLabels: LabelMap;
  focusAddress?: string;
  levelAccessLabels: LabelMap;
  nextLevelAccessDirection: string | undefined;
  showCurrentPosition?: boolean;
}

interface State {
  viewState: OrthographicViewState;
  minFocusArea: Size;
  focusTarget?: Point | LocationDetail;
  // The projected target and zoom level for the _end_ of the active animation, if animating
  animationTarget?: {
    target: Point;
    zoom: number;
  };
  endAnimationTarget?: {
    target: Point;
    focusArea: Size;
  };
  viewStateChangedOnMapInteraction?: boolean;
  blueDot?: {
    startPoint?: Point;
    point?: Point;
    position?: number;
  };
  interval?: NodeJS.Timeout;
  noTracking?: boolean;
}

const colorKeys: Dictionary<keyof typeof colors> = {
  Registers: "registersLabel",
  "Special Orders Desk": "specialOrdersDeskLabel"
};

function getColorKey(
  name: string,
  isSelected: boolean,
  defaultKey: keyof typeof colors
): keyof typeof colors {
  if (isSelected) {
    return "selectedElementLabel";
  }
  return colorKeys[name] ?? defaultKey;
}

class Map extends React.Component<Props, State> {
  displayedLabels: ProjectedLabel[];

  constructor(props: Props) {
    super(props);
    this.displayedLabels = [];
  }

  componentDidMount() {
    const {
      size: { width, height },
      store,
      selectedStorey,
      startPoint
    } = this.props;
    const storey = store.storeys.find((s) => s.storeyNumber === selectedStorey);
    if (!storey) return;
    const bounds = storey.bounds;
    const minFocusArea = {
      width: bounds.width * 0.1,
      height: bounds.height * 0.1
    };
    const viewState = initViewState({
      center: storey.center,
      viewport: { width, height },
      map: bounds
    });

    raiseMapEvent(MapEventName.rendering);
    const queryParams = new URLSearchParams(window.location.search);
    const storeId = queryParams.get("store");
    const interval = storeId ? undefined : setInterval(this.shiftStartPoint, 500);

    this.setState(
      { viewState, minFocusArea, interval: interval, noTracking: storeId != null },
      //{ viewState, minFocusArea },
      this.props.selectedElements && this.refocusViewport
    );
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    if (this.state.interval) clearInterval(this.state.interval);
  }

  componentDidUpdate(prev: Props) {
    const {
      store,
      mode,
      selectedElements,
      startPoint,
      focusAddress,
      viewportPadding,
      userSelectedStorey
    } = this.props;
    if (store.storeNumber !== prev.store.storeNumber) {
      // Force reraise of rendered event on store change
      this.displayedLabels = [];
      raiseMapEvent(MapEventName.rendering);
      this.onAfterRender = this.raiseRendered;
    }
    const elementOrModeChanged =
      mode !== prev.mode ||
      selectedElements !== prev.selectedElements ||
      startPoint !== prev.startPoint;
    const viewportPaddingChanged = viewportPadding !== prev.viewportPadding;
    const handleFocusAddress = focusAddress && focusAddress !== prev.focusAddress;
    const storeyChangedInList =
      userSelectedStorey && userSelectedStorey !== prev.userSelectedStorey;

    if (mode === MapMode.list && (handleFocusAddress || storeyChangedInList)) {
      this.handleFocusAddressChange();
    } else if (elementOrModeChanged) {
      // this.refocusViewport(undefined, false);
    } else if (viewportPaddingChanged) {
      this.adjustViewportPadding(prev.viewportPadding);
    }
  }
  shiftStartPoint = () => {
    if (this.state.blueDot?.point) {
      const { stops } = this.props.directions;
      const { position } = this.state.blueDot;
      const { startPoint } = this.props;

      if (stops.length > 0) {
        //move along x
        const steps = stops[0].points;
        if (position! >= steps.length - 1 && startPoint) {
          this.setState({ blueDot: { point: [...startPoint] as Point, position: 0, startPoint } });
          return;
        }
        const point = this.state.blueDot.point;
        const { 0: xCur, 1: yCur, 2: zCur } = steps[position!];
        const { 0: xNext, 1: yNext, 2: zNext } = steps[position! + 1];

        if (xCur === xNext) {
          const moveUp = yNext - yCur > 0;
          if ((moveUp && point[1] >= yNext) || (!moveUp && point[1] <= yNext)) {
            this.setState({ blueDot: { position: position! + 1, point, startPoint } });
            return;
          }
          point[1] = this.state.blueDot.point[1] + (moveUp ? 1000 : -1000);
        }

        if (yCur === yNext) {
          const moveRight = xNext - xCur > 0;
          if ((moveRight && point[0] >= xNext) || (!moveRight && point[0] <= xNext)) {
            this.setState({ blueDot: { position: position! + 1, point, startPoint } });
            return;
          }
          point[0] = this.state.blueDot.point[0] + (moveRight ? 1000 : -1000);
        }

        this.setState({ blueDot: { point: point, position, startPoint } });
      }
    }
    if (
      (!this.state.blueDot?.point || this.state.blueDot?.startPoint != this.props.startPoint) &&
      this.props.startPoint
    ) {
      const { startPoint } = this.props;

      this.setState({
        blueDot: { point: [...startPoint] as Point, position: 0, startPoint }
      });
    }
    if (!this.props.startPoint && this.state.blueDot?.point) {
      this.setState({
        blueDot: undefined
      });
    }
  };
  onViewStateChange: ViewStateChangeCallback<OrthographicViewState> = ({ viewState }) => {
    const {
      store: { storeys },
      viewportPadding,
      selectedStorey
    } = this.props;
    const storey = storeys.find((s) => s.storeyNumber === selectedStorey);
    if (!storey) return;
    clampTarget(viewState, storey.bounds, viewportPadding);
    this.setState({ viewState, viewStateChangedOnMapInteraction: true });
  };

  clearAnimationTarget = () => {
    this.setState({ animationTarget: undefined });

    const { viewportPadding } = this.props;
    const { endAnimationTarget, viewState, minFocusArea } = this.state;

    if (endAnimationTarget?.target) {
      this.animateViewState({
        target: endAnimationTarget.target,
        previous: viewState,
        focusArea: endAnimationTarget.focusArea,
        minFocusArea,
        viewportPadding
      });

      this.setState({ endAnimationTarget: undefined });
    }
  };

  // A wrapper to manage setting & clearing `animationTarget` when animating
  animateViewState = (config: AnimateViewStateConfig) => {
    const viewState = animateViewState({
      ...config,
      onTransitionEnd: this.clearAnimationTarget
    });
    const { target, zoom } = viewState;
    this.setState({
      viewState,
      animationTarget: { target, zoom },
      viewStateChangedOnMapInteraction: false
    });
  };

  recenterViewport = () => {
    const { store, selectedStorey, viewportPadding } = this.props;
    const { viewState } = this.state;
    const storey = store.storeys.find((s) => s.storeyNumber === selectedStorey);
    if (!storey) return;

    this.animateViewState({
      target: storey.center,
      focusArea: storey.bounds,
      previous: viewState,
      viewportPadding
    });
  };

  animateProductFocus = (target: Point, focusArea: Size) => {
    if (this.isCentered()) {
      const { viewState, minFocusArea } = this.state;
      const { viewportPadding } = this.props;
      // Animate viewport to the center of the selection
      return this.animateViewState({
        target,
        previous: viewState,
        focusArea,
        minFocusArea,
        viewportPadding
      });
    }
    //zoom out first so user can see the whole picture
    // then animate viewport to the center of the selection
    this.setState({
      endAnimationTarget: {
        target,
        focusArea
      }
    });
    this.recenterViewport();
  };

  isCentered = () => {
    const { store, selectedStorey, viewportPadding } = this.props;
    const { viewState } = this.state;

    const storey = store.storeys.find((s) => s.storeyNumber === selectedStorey);
    if (!storey) return;

    const targetViewState = animateViewState({
      target: storey.center,
      focusArea: storey.bounds,
      previous: viewState,
      viewportPadding
    });

    return (
      viewState?.target[0] === targetViewState?.target[0] &&
      viewState?.target[1] === targetViewState?.target[1] &&
      viewState?.target[2] === targetViewState?.target[2]
    );
  };

  refocusViewport = (focalPoints?: Point[], zoomout = true) => {
    const {
      mode,
      selectedElements,
      startPoint,
      focusAddress,
      waypoints,
      visitedLocations,
      userSelectedStorey,
      selectedStorey
    } = this.props;

    if (userSelectedStorey && selectedStorey !== userSelectedStorey) return;

    if (!focalPoints) {
      //use focus data if it's available
      if (focusAddress) {
        const address = focusAddress?.toLowerCase();
        const location = [...waypoints, ...visitedLocations].find((l) => {
          return l.address.toLowerCase() === address;
        });
        this.setState({ focusTarget: location });

        focalPoints = (location?.polygons || []).length > 0 ? [location?.point!] : [];
      } else {
        focalPoints = selectedElements
          ?.map((e) => e.polygon)
          .concat(startPoint && mode !== MapMode.store ? [[startPoint]] : [])
          .flat();
      }
    }

    //BUNIBEES-3072: we want to show full path from start point when in location mode
    focalPoints = focalPoints.concat(startPoint && mode === MapMode.location ? [startPoint] : []);

    if (focalPoints.length > 0) {
      const boundingRectangle = getBoundingRectangle(focalPoints);
      const { x, y, width, height } = boundingRectangle;
      const target = [x + width / 2, y + height / 2] as Point;

      if (zoomout) return this.animateProductFocus(target, boundingRectangle);

      //PF is sending extra request for list mode, don't do anything if the focus is the same as prev
      //to avoid flickering
      if (mode === MapMode.list && !this.state.viewStateChangedOnMapInteraction) return;

      return this.animateViewState({
        target: target,
        focusArea: boundingRectangle,
        previous: this.state.viewState,
        viewportPadding: this.props.viewportPadding,
        minFocusArea: this.state.minFocusArea
      });
    }
    this.recenterViewport();
  };

  // Recenter viewport based on the current and previous viewport padding
  adjustViewportPadding = (prevViewportPadding: Padding | undefined) => {
    // This depends heavily on the existence of `animationTarget` in state (if there's an animation in progress)
    // because when interpolating the viewstate changes, viewState will contain the *current* (i.e. interpolated) target & zoom,
    // rather than the destination target & zoom.
    const { viewportPadding } = this.props;
    if (!viewportPadding) return;

    const { viewState, animationTarget } = this.state;

    const { zoom } = animationTarget ?? viewState;
    let { target } = animationTarget ?? viewState;
    if (prevViewportPadding) {
      const prevViewportOffset = calculateViewportOffset(prevViewportPadding);
      const viewportOffset = calculateViewportOffset(viewportPadding);
      if (prevViewportOffset.dx || prevViewportOffset.dy) {
        const delta = {
          dx: viewportOffset.dx - prevViewportOffset.dx,
          dy: viewportOffset.dy - prevViewportOffset.dy
        };
        target = adjustPoint(target, delta, viewState.zoom);
      }
    }

    this.animateViewState({
      target,
      previous: { ...viewState, zoom },
      viewportPadding
    });
  };

  raiseRendered = () => {
    this.onAfterRender = undefined;
    raiseMapEvent(MapEventName.rendered);
  };

  onAfterRender? = this.raiseRendered;

  isLabelVisible = (props: VisibilityProps): boolean => {
    const availableLabel = checkLabelEligibility({ ...props, labels: this.displayedLabels });
    if (availableLabel?.name !== props.name) {
      return false;
    }
    this.displayedLabels.push(availableLabel);
    return true;
  };

  focusLocation = (location: LocationDetail, mapInteraction = true) => {
    if (mapInteraction) {
      raiseWaypointFocusEvent(location);
    } else {
      this.refocusViewport(location.polygons.flat().concat([location.point]));
    }
    this.setState({ focusTarget: location });
  };

  focusStartPoint = (mapInteraction = true) => {
    const { startAddress, startPoint } = this.props;
    if (startAddress && startPoint) {
      this.refocusViewport([startPoint]);
      if (mapInteraction) {
        raiseStartAddressFocusEvent(startAddress);
      } else {
        this.setState({ focusTarget: startPoint });
      }
    }
  };

  handleFocusAddressChange = () => {
    const { focusAddress, waypoints, visitedLocations } = this.props;
    const address = focusAddress?.toLowerCase();

    if (focusAddress) {
      const location = [...waypoints, ...visitedLocations].find((l) => {
        return l.address.toLowerCase() === address;
      });

      if (location) {
        this.focusLocation(location, false);
        return;
      }
      const { startAddress } = this.props;
      if (address === startAddress?.toLowerCase()) {
        this.focusStartPoint(false);
        return;
      }
    }
    this.setState({ focusTarget: undefined });
  };

  getFocusClass = (target: LocationDetail | Point) => {
    return target === this.state?.focusTarget ? "focused" : "unfocused";
  };

  render() {
    if (!this.state) return <LoadingIndicator />;

    const {
      store,
      selectedStorey,
      mode,
      selectedElements,
      startPoint,
      showLandmarkPins,
      showDirections,
      outline,
      waypoints,
      visitedLocations,
      directions: { stops },
      aisleNumberLabels,
      departmentLabels,
      landmarkLabels,
      entranceLabels,
      levelAccessLabels,
      nextLevelAccessDirection
    } = this.props;
    const { viewState } = this.state;
    const { zoom } = viewState;
    this.displayedLabels = [];

    const coordinateSystem = COORDINATE_SYSTEM.CARTESIAN;

    const storey = store.storeys.find((s) => s.storeyNumber === selectedStorey);
    if (!storey) return;
    const layers = [
      new PolygonLayer<Polygon>({
        id: "Shop Floor",
        coordinateSystem,
        visible: true,
        opacity: 1,
        data: outline,
        getPolygon: (polygon) => polygon,
        filled: true,
        getFillColor: colors.shopFloor,
        stroked: false,
        extruded: false,
        pickable: false
      }),
      new PolygonLayer<Bay>({
        id: "Bays",
        coordinateSystem,
        visible: true,
        opacity: 1,
        data: storey.bays,
        getPolygon: ([, , polygon]) => polygon,
        filled: true,
        getFillColor: colors.bay,
        stroked: false,
        extruded: false,
        pickable: false
      }),
      new PolygonLayer<NamedPolygon>({
        id: "Landmarks",
        coordinateSystem,
        visible: true,
        opacity: 1,
        data: storey.landmarks,
        getPolygon: ([, polygon]) => polygon,
        filled: true,
        getFillColor: ([name]) => {
          if (name.startsWith("Registers")) return colors.registers;
          if (name.startsWith("Special Orders Desk")) return colors.specialOrdersDesk;
          return colors.landmark;
        },
        stroked: true,
        getLineColor: ([name]) => {
          if (name.startsWith("Registers")) return colors.registersBorder;
          if (name.startsWith("Special Orders Desk")) return colors.specialOrdersDeskBorder;
          return colors.landmarkBorder;
        },
        lineWidthMinPixels: 1,
        extruded: false,
        pickable: false
      }),
      new PolygonLayer<Polygon>({
        id: "Outline",
        coordinateSystem,
        visible: true,
        opacity: 1,
        data: [storey.outline],
        getPolygon: (polygon) => polygon,
        filled: false,
        stroked: true,
        getLineColor: colors.outline,
        lineWidthMinPixels: 2,
        extruded: false,
        pickable: false
      }),
      new PolygonLayer({
        id: "Selected",
        coordinateSystem,
        visible: true,
        opacity: 0.75,
        data: selectedElements,
        getPolygon: (p) => p.polygon,
        filled: true,
        getFillColor: (p) => p.colors.fill,
        stroked: true,
        getLineColor: (p) => p.colors.stroke,
        lineWidthMinPixels: 1,
        extruded: false,
        pickable: false
      }),
      new PathLayer({
        id: "Navigation-Solid",
        coordinateSystem,
        visible: showDirections,
        opacity: 1,
        data: stops,
        getPath: (stop) => stop.points,
        getColor: (stop) => stop.color,
        widthMinPixels: 3,
        widthMaxPixels: 4,
        pickable: false
      }),
      createLabelLayers(aisleNumberLabels, zoom, {
        id: "Aisle Numbers",
        visible: true,
        getPosition: (l) => l.point,
        getText: (l) => l.text,
        getColor: colors.aisleNumbers,
        size: zoom < -7.5 ? 8 : zoom < -7 ? 10 : zoom < -6.5 ? 12 : 16,
        stroke: true
      }),
      new PolygonLayer<NamedPolygon>({
        id: "LevelAccess",
        coordinateSystem,
        visible: true,
        opacity: 1,
        data: storey.levelAccess,
        getPolygon: ([, polygon]) => polygon,
        filled: true,
        getFillColor: () => colors.levelAccess,
        stroked: true,
        getLineColor: () => colors.levelAccessBorder,
        lineWidthMinPixels: 1,
        extruded: false,
        pickable: false
      }),
      createLabelLayers(levelAccessLabels, zoom, {
        id: "LevelAccess",
        visible: true,
        getPosition: (l) => l.point,
        getText: (l) => l.text,
        getColor: colors.levelAccessName,
        size: zoom > -6.5 ? 16 : 12,
        lineHeight: 0.8,
        stroke: true
      })
    ];

    const view = new OrthographicView({ controller: true });
    const waypointStorey = waypoints.filter((w) => w.storey === selectedStorey);
    const visitedLocationsStorey = visitedLocations.filter((l) => l.point[2] === selectedStorey);

    const showStartPoint =
      startPoint &&
      showDirections &&
      waypointStorey.length > 0 &&
      mode !== MapMode.store &&
      !visitedLocationsStorey.some((l) => l.point === startPoint);
    const { getFocusClass } = this;

    return (
      <div
        key="deck"
        className="flex-1 relative"
        style={{
          backgroundColor: rgbToHex(colors.background),
          overflow: "hidden"
        }}
      >
        <DeckGL
          width="100%"
          height="100%"
          views={view}
          viewState={viewState}
          onViewStateChange={this.onViewStateChange}
          onAfterRender={this.onAfterRender}
          layers={layers}
        >
          <MapElementLabels
            labelMap={entranceLabels}
            selectedElements={selectedElements}
            zoom={zoom}
            isLabelVisible={this.isLabelVisible}
            getColor={() => colors.entrances}
            fontSize={zoom > -6.5 ? 16 : 12}
          />
          <MapElementLabels
            labelMap={departmentLabels}
            selectedElements={selectedElements}
            zoom={zoom}
            isLabelVisible={this.isLabelVisible}
            getColor={(l) =>
              selectedElements?.find((e) => e.address === `department:${l.name}`)?.colors?.text ??
              colors.departmentName
            }
          />
          {!showLandmarkPins && (
            <MapElementLabels
              labelMap={landmarkLabels}
              selectedElements={selectedElements}
              zoom={zoom}
              isLabelVisible={this.isLabelVisible}
              fontSize={zoom > -6.5 ? 12 : 10}
              getColor={(l) => {
                const selectedElementColor = selectedElements?.find((e) =>
                  e.address.startsWith(l.name)
                )?.colors?.text;
                return selectedElementColor ?? colors[getColorKey(l.name, false, "landmarkLabel")];
              }}
            />
          )}
          {showLandmarkPins && (
            <LandmarkPins
              landmarks={storey.landmarks}
              zoom={zoom}
              selectedElements={selectedElements}
              isLabelVisible={this.isLabelVisible}
            />
          )}
          {showStartPoint && (
            <MapPoint
              key="start-point"
              point={startPoint!}
              yPosition="bottom"
              onClick={this.focusStartPoint}
            >
              <StartPin className={getFocusClass(startPoint!)} />
            </MapPoint>
          )}
          {this.state.blueDot?.point && (
            <MapPoint
              key="tracking"
              point={this.state.blueDot.point!}
              onClick={this.focusStartPoint}
            >
              <StartPin className={getFocusClass(startPoint!)} tracking />
            </MapPoint>
          )}
          {visitedLocationsStorey.map((l, i) => (
            <MapPoint
              key={`visited-location-${i}`}
              point={l.point}
              yPosition="bottom"
              onClick={() => this.focusLocation(l)}
            >
              <VisitedPin style={{ marginBottom: -3 }} className={getFocusClass(l)} />
            </MapPoint>
          ))}
          {[...waypointStorey].reverse().map((w, i) => (
            <MapPoint
              key={`waypoint-${w.number}-${i}`}
              point={w.point}
              yPosition="bottom"
              onClick={() => this.focusLocation(w)}
            >
              {w.address.indexOf("travel") === -1 && w.address.indexOf("lift") === -1 ? (
                this.props.showCurrentPosition ? (
                  <PositionIndicator n={w.number}></PositionIndicator>
                ) : (
                  <Pin
                    className={getFocusClass(w)}
                    number={w.number}
                    style={{
                      marginBottom: -3,
                      color: hexColors.selectedElementLabel
                    }}
                  />
                )
              ) : (
                <StoreyPin
                  direction={nextLevelAccessDirection!}
                  className={getFocusClass(w)}
                  style={{
                    marginBottom: -3,
                    color: hexColors.selectedElementLabel
                  }}
                />
              )}
            </MapPoint>
          ))}
        </DeckGL>
      </div>
    );
  }
}

export type MapProps = Props;
export default Map;
