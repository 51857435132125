import { getCenter } from "../services";
import { useMemo } from "react";
import { Point } from "@deck.gl/core";
import { multilinify } from "../services";
import { NamedPoint, NamedPolygon, Polygon } from "../types";

export interface ZoomLevels {
  [name: string]: number | undefined;
}

export interface Label {
  name: string;
  text: string;
  point: Point;
  minZoomLevel: number;
}

export type LabelMap = Map<number, Label[]>;

export function useLabels(
  source: NamedPoint[] | NamedPolygon[] | undefined,
  defaultZoomLevel = -Infinity,
  zoomLevels?: ZoomLevels
): LabelMap {
  const labels = useMemo<Label[]>(() => {
    if (!source?.length) return [];
    return (source as [string, Point | Polygon][])
      .map(([name, shape]) => {
        const isPolygon = Array.isArray(shape[0]);
        const point = isPolygon ? getCenter(shape as Polygon) : (shape as Point);
        const loweredName = name?.toLowerCase();
        const text = name ? multilinify(name) : "";
        const minZoomLevel = zoomLevels?.[loweredName] ?? defaultZoomLevel;
        return {
          name,
          text,
          point,
          minZoomLevel
        };
      })
      .filter((label) => label.text);
  }, [source, zoomLevels, defaultZoomLevel]);

  const map = new Map<number, Label[]>();
  for (const label of labels) {
    const item = map.get(label.minZoomLevel) ?? [];
    item.push(label);
    map.set(label.minZoomLevel, item);
  }
  return map;
}
