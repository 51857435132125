import { IPriorityQueue, Weighted } from "../../types";

export class PriorityQueue<T> implements IPriorityQueue {
  items: Weighted<T>[] = [];
  private size = 0;

  private parent(i: number): number {
    return Math.floor((i - 1) / 2);
  }

  private left(i: number): number {
    return 2 * i + 1;
  }

  private right(i: number): number {
    return 2 * i + 2;
  }

  private swap(a: number, b: number) {
    const temp = this.items[a];
    this.items[a] = this.items[b];
    this.items[b] = temp;
  }

  private heapify(index: number) {
    const left = this.left(index);
    const right = this.right(index);
    let smallest = index;
    if (left < this.size && this.items[left][1] < this.items[index][1]) smallest = left;
    if (right < this.size && this.items[right][1] < this.items[smallest][1]) smallest = right;
    if (smallest !== index) {
      this.swap(index, smallest);
      this.heapify(smallest);
    }
  }

  enqueue(item: Weighted<T>) {
    // First insert the new key at the end
    this.size++;
    let index = this.size - 1;
    this.items[index] = item;

    // Fix the min heap property if it is violated
    while (index !== 0 && this.items[this.parent(index)][1] > this.items[index][1]) {
      this.swap(index, this.parent(index));
      index = this.parent(index);
    }
  }

  dequeue(): Weighted<T> {
    if (this.size <= 0) throw "no items left in the queue";
    if (this.size === 1) {
      this.size--;
      return this.items[0];
    }

    // Store the minimum value, and remove it from heap
    const minimum = this.items[0];
    this.items[0] = this.items[this.size - 1];
    this.size--;
    this.heapify(0);

    return minimum;
  }

  isEmpty(): boolean {
    return this.size === 0;
  }
}
