import { Color } from "@deck.gl/core";
import { DeckGLChildFunctionComponent, DeckGLChildProps } from "@deck.gl/react";
import React from "react";
import { Label, LabelMap } from "../hooks/use-labels";
import { SelectedElement } from "../hooks/use-selected-elements";
import { sortLandmark, VisibilityProps } from "../services";
import { LabelPosition } from "../types";
import MapLabel from "./MapLabel";

interface Props {
  labelMap: LabelMap;
  selectedElements?: SelectedElement[];
  zoom: number;
  isLabelVisible(props: VisibilityProps): boolean;
  getColor(l: Label): Color;
  fontSize?: number;
}

interface FullProps extends Props, DeckGLChildProps {}

const MapElementLabels: DeckGLChildFunctionComponent<Props> = (props) => {
  const { labelMap, selectedElements, zoom, viewport, isLabelVisible, getColor, fontSize } =
    props as FullProps;
  const labels = [...labelMap.entries()].flatMap(([, labels]) => labels);

  // sort landmark by priority, make sure we show all selectedElements first
  const sortedList = [
    ...labels.filter((l) => selectedElements?.find((s) => s.address.startsWith(l.name))),
    ...labels
      .filter((l) => !selectedElements?.find((s) => s.address.startsWith(l.name)))
      .sort((first, second) => sortLandmark(first.name, second.name))
  ];

  return (
    <>
      {sortedList
        .filter((label) => {
          //force show selected
          const alwaysShow = !!selectedElements?.find((e) => e.address.startsWith(label.name));
          return isLabelVisible({
            name: label.name,
            zoom,
            point: viewport.project(label.point),
            includePin: false,
            alwaysShow,
            labelPosition: LabelPosition.center,
            scale: fontSize
          });
        })
        .map((label, i) => {
          const color = getColor(label);
          return (
            <MapLabel
              key={label.name + i}
              point={label.point}
              label={label.name}
              color={color}
              {...props}
            />
          );
        })}
    </>
  );
};

MapElementLabels.deckGLViewProps = true;

export type MapElementLabelsProps = Props;
export default MapElementLabels;
