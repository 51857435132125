import React, { CSSProperties } from "react";
import { Point, Color } from "@deck.gl/core";
import { DeckGLChildProps, DeckGLChildFunctionComponent } from "@deck.gl/react";
import colors, { rgbToHex } from "../constants/colors";

const getFontSize = (size: number): CSSProperties => ({
  top: 0,
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  textAlign: "center",
  fontSize: size
});

interface Props {
  point: Point;
  color?: Color;
  label?: string;
  labelClassName?: string;
  fontSize?: number;
  zoom: number;
}

interface FullProps extends Props, DeckGLChildProps {}

const MapLabel: DeckGLChildFunctionComponent<Props> = (props) => {
  const {
    point,
    color = colors.mapLocation,
    label,
    labelClassName,
    fontSize = 12,
    viewport
  } = props as FullProps;
  const [left, top] = viewport.project(point);

  const style: CSSProperties = {
    left,
    top,
    transform: `translateX(-50%) translateY(-50%)`,
    color: rgbToHex(color)
  };
  return (
    <div className="absolute bg-transparent text-xs" style={style}>
      {label && (
        <div className="absolute leading-none" style={getFontSize(fontSize)}>
          <p className={"text-stroke " + (labelClassName ?? "")}>{label?.replace(/ &/, " &")}</p>
        </div>
      )}
    </div>
  );
};

MapLabel.deckGLViewProps = true;

export type LabelProps = Props;
export default MapLabel;
