import axios from "axios";
import Cache from "../utils/cache";
import { Service, ServiceDTO, SERVICES } from "../types";
import { HOUR } from "../constants/util";


const api = axios.create({
  baseURL: `/api`
});

const cache = new Cache("services", HOUR);

// NOTE: This is temporary and we'll be writing a script so this won't be required and we'll just check on the service type and not name
function stripFloorFromService(name: string): string {
  if (!name) return name;
  
  const position = name.indexOf("-");
  if (position === -1) return name;

  return name.slice(0, position).trim();
}

function getServiceIcon(service: ServiceDTO): string {
  const name = stripFloorFromService(service.name);
  const services = SERVICES.find(s => s.type === service.type || s.name === name)

  return services?.iconUrl ?? "";
}

export async function getServices(storeNumber: string, mapId: string) {
  const key = `${storeNumber}/${mapId}-v6`;
  return cache
    .getOrAdd(key, async () =>
      (await api.get<ServiceDTO[]>(`/${storeNumber}/${mapId}-services.json`)).data.map((service: ServiceDTO) => {
        const s: Service = {
          name: service.name,
          icon: getServiceIcon(service),
          location: service.location,
          storeyNumber: service.storeyNumber,
        }
        return s;
      }).sort((a, b) => {
        const loweredNameA = a.name?.toLowerCase();
        const loweredNameB = b.name?.toLowerCase();
        if (loweredNameA < loweredNameB) return -1;
        if (loweredNameA > loweredNameB) return 1;
        return 0;
      })
    )
    .catch(() => []);
}
