import React from "react";
import { DeckGLChildFunctionComponent, DeckGLChildProps } from "@deck.gl/react";

import { getCenter, sortLandmark, VisibilityProps } from "../services";
import { Dictionary } from "../types";
import colors from "../constants/colors";
import MapLocation from "./MapLocation";
import { SelectedElement } from "../hooks/use-selected-elements";
import Pin from "./atoms/Pin";
import { LabelPosition, NamedPolygon } from "../types";

interface Props {
  landmarks: NamedPolygon[];
  selectedElements?: SelectedElement[];
  zoom: number;
  isLabelVisible(props: VisibilityProps): boolean;
}

interface FullProps extends Props, DeckGLChildProps {}

const colorKeys: Dictionary<keyof typeof colors> = {
  Registers: "registersLabel",
  "Special Orders Desk": "specialOrdersDeskLabel"
};

const labelPositions: Dictionary<LabelPosition> = {
  "Hire Shop": LabelPosition.bottom,
  "Information Desk": LabelPosition.top,
  "Cutting Saw": LabelPosition.right,
  "Special Orders Desk": LabelPosition.right,
  Registers: LabelPosition.top
};

function getColorKey(name: string, isSelected: boolean): keyof typeof colors {
  if (isSelected) {
    return "selectedElementLabel";
  }
  return colorKeys[name] ?? "landmarkLabel";
}

const LandmarkPins: DeckGLChildFunctionComponent<Props> = (props) => {
  const { landmarks, selectedElements, zoom, viewport, isLabelVisible } = props as FullProps;
  // sort landmark by priority, make sure we show all selectedElements first
  const sortedList = [
    ...landmarks.filter(([, polygon]) => selectedElements?.find((s) => s.polygon === polygon)),
    ...landmarks
      .filter(([, polygon]) => !selectedElements?.find((s) => s.polygon === polygon))
      .sort(([first], [second]) => sortLandmark(first, second))
  ];
  return (
    <>
      {sortedList
        .filter(([name, polygon]) => {
          const alwaysShow = !!selectedElements?.find((e) => e.polygon === polygon);

          return isLabelVisible({
            name,
            zoom,
            point: viewport.project(getCenter(polygon)),
            labelPosition: labelPositions[name],
            includePin: true,
            alwaysShow
          });
        })
        .map((landmark, i) => {
          const [name, polygon] = landmark;
          const selectedElement = selectedElements?.find((e) => e.polygon === polygon);
          const color = selectedElement?.colors?.text ?? colors[getColorKey(name, false)];
          return (
            <MapLocation
              key={name + i}
              point={getCenter(polygon)}
              label={name}
              labelPosition={labelPositions[name]}
              labelClassName="font-semibold"
              icon={<Pin className={selectedElement ? "focused" : ""} />}
              color={color}
              {...props}
            />
          );
        })}
    </>
  );
};

LandmarkPins.deckGLViewProps = true;

export type LandmarkPinsProps = Props;
export default LandmarkPins;
