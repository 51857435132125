import { useState, useEffect } from "react";
import api from "../api";
import { raiseStoreLoadedEvent, raiseStoreClearedEvent } from "../services";
import { StoreData } from "../types";

export function useStore(storeNumber: string, supportsMultiStorey: boolean) {
  const [store, setStore] = useState<StoreData>();

  useEffect(() => {
    if (storeNumber) {
      api
        .getStore(storeNumber)
        .then((newStore) => {
          if (newStore && (newStore.storeys.length === 1 || (newStore.storeys.length > 1 && supportsMultiStorey)))  {
            setStore(newStore);
            // load first storey by default
            raiseStoreLoadedEvent(newStore, 1);
          } else {
            setStore(undefined);
            raiseStoreClearedEvent(storeNumber, "No map found");
          }
        })
        .catch(() => {
          raiseStoreClearedEvent(storeNumber, "Error loading map");
        });
    } else {
      setStore(undefined);
      raiseStoreClearedEvent(storeNumber, "Missing/empty store number");
    }
  }, [storeNumber, supportsMultiStorey]);

  return store;
}
