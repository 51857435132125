import { distinct, isTruthy } from "../util";
import {
  DebugEvent,
  LocationDetail,
  MapEventName,
  MapMode,
  StartPointFocusEvent,
  StoreClearedEvent,
  StoreData,
  StoreLoadedEvent,
  StoreMapProps,
  StoreUpdateEvent,
  StoreyChangedEvent,
  Waypoint,
  WaypointDetail,
  WaypointFocusEvent,
  WaypointUpdateEvent
} from "../../types";
import { getDisplayName } from "..";

interface SimpleMapEvent {
  name: MapEventName;
}

export function raiseMapEvent(event: MapEventName | MapEvent) {
  if (typeof event === "string") {
    event = { name: event };
  }
  if (window.ReactNativeWebView) {
    const payload = JSON.stringify(event);
    window.ReactNativeWebView.postMessage(payload);
  } else {
    window.ParentWindow && window.ParentWindow.postMessage(event, "*");
    console.log("[STUB] ReactNativeWebView.postMessage(", event, ")");
  }
}

export function raiseStoreLoadedEvent(store: StoreData, selectedStorey: number) {
  const eventData = getStoreEvent(store, selectedStorey, MapEventName.store);
  if (!eventData) return;

  raiseMapEvent(eventData);
}

export function raiseStoreyChangedEvent(selectedStorey: number) {
  raiseMapEvent({
    name: MapEventName.storeyChanged,
    selectedStorey
  });
}

function getStoreEvent(
  store: StoreData,
  selectedStorey: number,
  eventName: MapEventName.store
): StoreUpdateEvent | null {
  const storey = store.storeys.find((s) => s.storeyNumber === selectedStorey);
  if (!storey) return null;

  const departments = storey.departments;
  const entrances = storey.entrances;

  const { storeNumber, storeName, locations, services, wayfindingGraph } = store;
  return {
    name: eventName,
    storeNumber,
    storeName,
    mapId: store.mapId,
    addresses: Object.keys(locations),
    departments: distinct(departments.map(([name]) => name)).sort(),
    entrances: distinct(entrances.map(([name]) => name)).sort(),
    services,
    nullNodes: wayfindingGraph?.nullNodes,
    selectedStorey,
    storeys: store.storeys.map((s) => ({
      storeyNumber: s.storeyNumber,
      name: s.name,
      nameAbbr: s.nameAbbreviated
    }))
  };
}

export function raiseStoreClearedEvent(storeNumber: string, reason: string) {
  raiseMapEvent({
    name: MapEventName.cleared,
    storeNumber,
    reason
  });
}

function mapWaypoint({ address, items, number, levelAccess }: LocationDetail): Waypoint {
  const result: Waypoint = {
    address: levelAccess ? address : getDisplayName(address),
    itemNumbers: items.map((i) => i.itemNumber).filter(isTruthy),
    cardNumber: number,
    isLevelChange: false
  };

  if (levelAccess) {
    result.isLevelChange = true;
    const direction = levelAccess.direction === "down" ? "downstairs" : "upstairs";

    result.levelAccess = {
      accessType: levelAccess.accessType,
      title: `Go ${direction}`,
      description: `More products ${direction}`,
      direction: levelAccess.direction
    };
  }

  return result;
}

export function raiseWaypointUpdateEvent(
  mode: MapMode,
  [waypoints, locations]: [WaypointDetail[], LocationDetail[]]
) {
  if (mode === MapMode.store) return;
  raiseMapEvent({
    name: MapEventName.waypointUpdate,
    waypoints: [...waypoints, ...locations].map(mapWaypoint)
  });
}

export function raiseWaypointFocusEvent(waypoint: LocationDetail) {
  raiseMapEvent({
    name: MapEventName.waypointFocus,
    waypoint: mapWaypoint(waypoint)
  });
}

export function raiseStartAddressFocusEvent(address: string) {
  raiseMapEvent({ name: MapEventName.startAddressFocus, address });
}

export function raiseDebugEvent(message: string) {
  raiseMapEvent({ name: MapEventName.debug, message });
}

export type MapEvent =
  | SimpleMapEvent
  | StoreLoadedEvent
  | StoreClearedEvent
  | WaypointUpdateEvent
  | WaypointFocusEvent
  | StartPointFocusEvent
  | DebugEvent
  | StoreyChangedEvent;

window.StoreMap = window.StoreMap ?? {
  render(props: StoreMapProps) {
    console.warn("[STUB] StoreMap.render(", props, ")");
  },
  debug(log: string) {
    console.warn("[STUB] StoreMap.debug(", log, ")");
  }
};
