import { OrthographicViewState, Point } from "@deck.gl/core";

import OrthographicInterpolator from "../geometry/interpolators";
import { clamp } from "../util";
import { calclulateDistance } from "../geometry/geometry";
import { Size, Padding, Vector, Rectangle, AnimateViewStateConfig } from "../../types";

const INTERPOLATOR = new OrthographicInterpolator();
const MIN_DURATION = 800;
const MAX_DURATION = 1200;
const MIN_ZOOM = -9.5;
const MAX_ZOOM = -3;
const X = 0;
const Y = 1;

function easeOutQuint(t: number) {
  return 1 + --t * t * t * t * t;
}

interface InitViewStateConfig {
  center: Point;
  viewport: Size;
  map: Size;
  zoom?: number;
}
export function initViewState({
  center: [x, y],
  viewport,
  map,
  zoom
}: InitViewStateConfig): OrthographicViewState {
  const { width, height } = viewport;
  const defaultZoom = calculateIdealZoom(viewport, map, 1.2);
  const [minZoom, maxZoom] = [Math.max(defaultZoom, MIN_ZOOM), MAX_ZOOM];
  return {
    target: [x, y, 0],
    zoom: zoom !== undefined ? zoom : defaultZoom,
    minZoom,
    maxZoom,
    width,
    height
  };
}

export function animateViewState({
  target: [x, y],
  previous,
  focusArea,
  minFocusArea,
  viewportPadding,
  minDuration = MIN_DURATION,
  maxDuration = MAX_DURATION,
  onTransitionEnd = () => {}
}: AnimateViewStateConfig): OrthographicViewState {
  let { width, height, zoom } = previous;
  let viewportOffset: Vector | undefined;

  if (viewportPadding) {
    if (width && height) {
      ({ width, height } = padViewport({ width, height }, viewportPadding));
    }
    viewportOffset = calculateViewportOffset(viewportPadding);
  }
  if (focusArea && width && height) {
    const idealZoom = calculateIdealZoom({ width, height }, focusArea, 1.3, minFocusArea);
    zoom = idealZoom !== undefined ? idealZoom : zoom;
    if (previous.maxZoom && zoom > previous.maxZoom) {
      zoom = previous.maxZoom;
    }
  }

  if (viewportOffset) {
    [x, y] = adjustPoint([x, y], viewportOffset, zoom);
  }

  const [previousX, previousY] = previous.target;
  const distance = calclulateDistance(x - previousX, y - previousY);
  const duration = clamp(Math.round(distance / 10.0), minDuration, maxDuration);

  return {
    ...previous,
    target: [x, y, 0],
    zoom,
    transitionDuration: duration,
    transitionEasing: easeOutQuint,
    transitionInterpolator: INTERPOLATOR,
    onTransitionEnd
  } as OrthographicViewState;
}

export function calculateIdealZoom(viewport: Size, target: Size, buffer: number, minSize?: Size) {
  let { width, height } = target;
  if (minSize) {
    width = Math.max(width, minSize.width);
    height = Math.max(height, minSize.height);
  }
  // At a zoom of 0, 1 unit === 1 pixel. Each successive zoom level doubles the value.
  // The formula is effectively: `viewport size = target size * Math.pow(2, zoom)`
  // which translates to `zoom = Math.log2(viewport size / target size)`.
  const sizeRatio =
    viewport.width / width < viewport.height / height
      ? viewport.width / (width * buffer)
      : viewport.height / (height * buffer);
  const idealZoom = Math.log2(sizeRatio);
  return clamp(idealZoom, MIN_ZOOM, MAX_ZOOM);
}

export function adjustPoint([x, y]: Point, viewportDistance: Vector, zoom: number): Point {
  const { dx, dy } = viewportDistance;
  // target size = viewport size / Math.pow(2, zoom)
  return [x + dx / Math.pow(2, zoom), y + dy / Math.pow(2, zoom)];
}

export function getTextSize(viewState: OrthographicViewState, baseSizePixels = 12) {
  // Warning: this was just guesswork and works well
  // with the current zoom range of -8 to -5.
  // I'm not sure how it will work with other zoom values.
  const zoomFactor = (-3 - viewState.zoom) / 5;
  return baseSizePixels / zoomFactor;
}

export function clampTarget(
  { target, zoom }: OrthographicViewState,
  bounds: Rectangle,
  viewportPadding?: Padding
) {
  let [minX, minY, maxX, maxY] = [
    bounds.x,
    bounds.y,
    bounds.x + bounds.width,
    bounds.y + bounds.height
  ];
  if (viewportPadding) {
    const viewportOffset = calculateViewportOffset(viewportPadding);
    [minX, minY] = adjustPoint([minX, minY], viewportOffset, zoom);
    [maxX, maxY] = adjustPoint([maxX, maxY], viewportOffset, zoom);
  }
  // Restrict viewport to bounds (don't allow a target outside the visible viewport rectangle)
  target[X] = clamp(target[X], minX, maxX);
  target[Y] = clamp(target[Y], minY, maxY);
}

function padViewport({ width, height }: Size, { top, bottom, left, right }: Padding): Size {
  width -= (left || 0) + (right || 0);
  height -= (top || 0) + (bottom || 0);
  return { width: Math.max(0, width), height: Math.max(0, height) };
}

export function calculateViewportOffset({ top, bottom, left, right }: Padding): Vector {
  return {
    dx: -((left || 0) / 2) + (right || 0) / 2,
    dy: -((top || 0) / 2) + (bottom || 0) / 2
  };
}
