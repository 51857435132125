import { Point3D, Point2D } from "@deck.gl/core";
import Iter from "es-iter";
import { distinct, sum, minBy, PriorityQueue, getSegmentKey, getAngle, getDistance } from "..";
import { FLOOR_CHANGE_PENALTY, OPTIMIZED_ROUTE_THRESHOLD } from "../../constants/graph";
import {
  Graph,
  GraphProperties,
  Dictionary,
  Edge,
  EdgeTransport,
  NearestNodeTransport,
  Path,
  PathSection,
  Polygon,
  Route,
  MapNode
} from "../../types";
import { findDesiredSortedNodes } from "./node";

function unpackTransportFactory(resolution: number) {
  return (value: number, index: number) => {
    const sign = index === 1 ? -1 : 1;
    return sign * value * resolution;
  };
}

export class GraphImpl implements Graph, GraphProperties {
  nodes: Dictionary<Point3D> = {};
  adjacencyList: Dictionary<Edge[]> = {};
  nearestNodes: Dictionary<Point3D> = {};
  nearestNodeAddresses: Dictionary<string> = {};
  nullNodes: Dictionary<string> = {};

  constructor(edgeTransport?: EdgeTransport, nearestNodeTransport?: NearestNodeTransport) {
    if (edgeTransport) {
      const unpackTransport = unpackTransportFactory(edgeTransport.resolution ?? 1000);
      for (const storey of edgeTransport.storeys) {
        for (const [n1, n2] of storey.data ?? []) {
          const storeyNumber = storey.storey;
          this.addEdges(
            [...n1.map(unpackTransport), storeyNumber] as Point3D,
            [...n2.map(unpackTransport), storeyNumber] as Point3D
          );
        }
      }

      for (const levelAccess of edgeTransport.levelAccesses) {
        this.addEdges(
          [levelAccess[0][0], -levelAccess[0][1], levelAccess[0][2]] as Point3D,
          [levelAccess[1][0], -levelAccess[1][1], levelAccess[1][2]] as Point3D
        );
      }
    }

    if (nearestNodeTransport) {
      const unpackTransport = unpackTransportFactory(nearestNodeTransport.resolution ?? 1000);
      for (const storey of nearestNodeTransport.storeys) {
        for (const [address, node] of Object.entries(
          (storey.data as Dictionary<Point2D | undefined>) ??
            ({} as Dictionary<Point2D | undefined>)
        )) {
          if (node) {
            this.addNearestNode(address, [...node.map(unpackTransport), storey.storey] as Point3D);
            continue;
          }
          this.nullNodes[address] = "nullNode";
        }
      }
    }
  }

  private findPath = (start: Point3D, end: Point3D): Path => {
    const { nodes, adjacencyList, resolveNode, simplifyPath } = this;

    const [startNode, endNode] = [start, end].map((n) => resolveNode(n!));
    const times = Object.fromEntries(
      Object.keys(nodes).map((n) => [n, n === startNode ? 0 : Infinity])
    );
    const backtrace: Dictionary<string> = {};
    const queue = new PriorityQueue<[string, number, number?]>();
    queue.enqueue([[startNode, start[2], undefined], 0]);

    while (!queue.isEmpty()) {
      const [[currentNode, currentStorey, angle]] = queue.dequeue()!;

      for (const neighbor of adjacencyList[currentNode]!) {
        let multiplier = angle !== undefined && neighbor.angle !== angle ? 1.1 : 1;
        // floor change so penalise
        if (neighbor.storey !== currentStorey) {
          multiplier = FLOOR_CHANGE_PENALTY;
        }

        const time = times[currentNode] + multiplier * neighbor.weight;
        if (time < times[neighbor.node]) {
          times[neighbor.node] = time;
          backtrace[neighbor.node] = currentNode;
          queue.enqueue([[neighbor.node, neighbor.storey, neighbor.angle], time]);
        }
      }
    }

    const path: Point3D[] = [nodes[endNode]!];
    let lastStep = endNode;
    while (lastStep !== startNode) {
      const previousNode = backtrace[lastStep]!;
      const currentNode = nodes[previousNode]!;
      if (!previousNode || !currentNode) {
        return {
          sections: [],
          distance: Infinity,
          cost: Infinity
        };
      }
      path.unshift(currentNode);
      lastStep = previousNode;
    }

    const simplfiedPath = simplifyPath(path);
    let distance = 0;
    const sections: PathSection[] = [
      { point: simplfiedPath[0], distance: 0 },
      ...simplfiedPath.slice(1).map((point, i) => {
        const previous = simplfiedPath[i];
        const segment = {
          point,
          distance: getDistance(previous, point)
        };
        distance += segment.distance;
        return segment;
      })
    ];
    return {
      sections,
      distance: distance,
      cost: times[endNode]
    };
  };

  optimizeRoute = (
    startAddress: string | undefined,
    startPoint: Point3D | undefined,
    destinations: Dictionary<Point3D>,
    toolshop: Polygon | undefined,
    debug = false
  ): Route => {
    if (!startAddress || !startPoint) return this.getSortedRoute(destinations);

    const destinationEntries = Object.entries(destinations);

    const timerLabel = `Graph.optimizeRoute([${startPoint}], ${JSON.stringify(destinations)})`;
    try {
      console.time(timerLabel);

      if (destinationEntries.length > OPTIMIZED_ROUTE_THRESHOLD) {
        return this.getSuboptimalRoute(startPoint, destinations, toolshop, startAddress);
      }

      const { nodes, findClosestNode, resolveNode, findPath, isGroupedByLevel } = this;
      const startNode = resolveNode(findClosestNode(startAddress, startPoint)!);

      const destinationNodes = destinationEntries
        .map(([address, point]) => ({
          node: findClosestNode(address, point!)!,
          address
        }))
        .sort((a, b) => (a.address > b.address ? 1 : a.address < b.address ? -1 : 0));
      const nonStartNodes = distinct(destinationNodes.map((x) => resolveNode(x.node)));

      const segmentCandidates: Dictionary<Path> = {};
      const reachableNonStartNodes = [];
      //find unavigatable nodes and remove it
      for (let i = nonStartNodes.length - 1; i >= 0; i--) {
        const destinationNode = nonStartNodes[i];
        const path = findPath(nodes[startNode], nodes[destinationNode]);

        if (path.cost === Infinity) {
          continue;
        }
        reachableNonStartNodes.push(nonStartNodes[i]);
        segmentCandidates[getSegmentKey(startNode, destinationNode)] = path;
      }
      // all combination of start node to reachableNodes for segment candidates are considered in previous loop
      const targetNodes = [...reachableNonStartNodes];

      for (const [n1, n2] of new Iter(targetNodes).combinations(2)) {
        segmentCandidates[getSegmentKey(n1, n2)] = findPath(nodes[n1], nodes[n2]);
        // TODO: should we just reverse instead of doing a findPath again?
        segmentCandidates[getSegmentKey(n2, n1)] = findPath(nodes[n2], nodes[n1]);
      }

      const nonStartPermutations = new Iter(reachableNonStartNodes).permutations(
        reachableNonStartNodes.length
      );
      let shortestRoute: Route = {
        start: nodes[startNode],
        nodes: [],
        segments: [],
        distance: Infinity,
        cost: Infinity
      };
      const routes: Route[] = [];
      for (const permutation of nonStartPermutations) {
        const routeCandidate = [startNode, ...permutation];

        //Making sure the route candidate is grouped by level as we want to let user fetch all items
        //from the same level first before moving to other level.
        if (!isGroupedByLevel(routeCandidate)) {
          continue;
        }

        const segments = routeCandidate
          .slice(0, routeCandidate.length - 1)
          .map((n1, i) => segmentCandidates[getSegmentKey(n1, routeCandidate[i + 1])]);

        const distance = sum(segments, (s) => s.distance);
        const cost = sum(segments, (s) => s.cost);
        const route = {
          start: nodes[startNode],
          nodes: [
            ...segments.map((s) => (s.sections.length ? s.sections[s.sections.length - 1] : []))
          ].map<MapNode>((n) => [
            (n as any)?.point,
            destinationNodes.filter((x) => x.node === (n as any)?.point).map((x) => x.address)
          ]),
          segments,
          distance,
          cost
        };
        if (debug) {
          routes.push(route);
        }
        if (route.cost < shortestRoute.cost) {
          shortestRoute = route;
        }
      }
      if (debug) {
        console.log(routes.sort((a, b) => a.cost - b.cost).slice(0, 100));
      }
      return shortestRoute;
    } finally {
      console.timeEnd(timerLabel);
    }
  };

  private convertToMapNodes(destinations: Dictionary<Point3D>): MapNode[] {
    const { findClosestNode } = this;
    return Object.entries(destinations).map(([address, point]) => {
      const node = findClosestNode(address, point);
      return [node!, [address]] as MapNode;
    });
  }

  //todo: rethink this, it's inaccurate
  private getSortedRoute = (destinations: Dictionary<Point2D>, startPointStorey = 1): Route => {
    const timerLabel = `Graph.sortRoute(${JSON.stringify(destinations)})`;
    console.time(timerLabel);

    const { findClosestNode } = this;
    const sortByStartLevel = (a: number, b: number, level: number) => {
      if (a === b && a === level) return 0;
      if (a === level) return -1;
      if (b === level) return 1;
      return 0;
    };

    const nodes = Object.entries(destinations)
      .map(([address, point]) => {
        const node = findClosestNode(address, point);
        return [node!, [address]] as MapNode;
      })
      .sort(([[ax, ay, az]], [[bx, by, bz]]) => {
        return (
          sortByStartLevel(az, bz, startPointStorey) ||
          this.compareNumber(az, bz) ||
          this.compareNumber(ax, bx) ||
          this.compareNumber(ay, by)
        );
      });

    const route: Route = {
      cost: 0,
      distance: 0,
      nodes: nodes,
      segments: nodes.map<Path>(([point]) => ({
        sections: [{ point, distance: 0 }],
        distance: 0,
        cost: 0
      })),
      start: [0, 0, 1]
    };

    console.timeEnd(timerLabel);
    return route;
  };

  private findAccessiblePath = (
    startNode: Point3D,
    nodes: MapNode[],
    index: number
  ): [Path, number] => {
    const { findPath } = this;
    let segment: Path = {
      cost: Infinity,
      distance: Infinity,
      sections: []
    };

    //keep looping to find accessible path and return the index of the accessible location
    while (index < nodes.length) {
      const [destination] = nodes[index];
      segment = findPath(startNode, destination);

      if (segment.cost !== Infinity) {
        break;
      }
      index++;
    }

    return [segment, index];
  };

  private getSuboptimalRoute = (
    startPoint: Point3D,
    destinations: Dictionary<Point3D>,
    toolshop: Polygon | undefined,
    startAddress: string | undefined
  ): Route => {
    const { findPath, findAccessiblePath } = this;

    let nodes = findDesiredSortedNodes(
      startPoint,
      this.convertToMapNodes(destinations),
      startAddress
    );
    console.log("suboptimal routes for nodes: ", nodes);
    const accessiblePath = findAccessiblePath(startPoint, nodes, 0);
    let [firstPath] = accessiblePath;
    const [, index] = accessiblePath;

    nodes = nodes.slice(index);
    const [firstNode] = nodes[0];
    const [lastNode] = nodes[nodes.length - 1];

    // only reverse the path if all the node on the same floor and entrance is close to last node
    if (firstNode !== lastNode && firstNode[2] === lastNode[2]) {
      const lastPath = findPath(startPoint, lastNode);
      if (firstPath.cost > lastPath.cost) {
        nodes.reverse();
        firstPath = lastPath;
      }
    }

    let { cost, distance } = firstPath;
    const reachableNodes: MapNode[] = [nodes[0]];
    const segments = [firstPath];

    for (let i = 1; i < nodes.length; i++) {
      const [fromPoint] = nodes[i - 1];

      const [segment, assesibleIndex] = this.findAccessiblePath(fromPoint, nodes, i);

      reachableNodes.push(nodes[assesibleIndex]);
      segments.push(segment);

      cost += segment.cost;
      distance += segment.distance;
      i = assesibleIndex;
    }

    const route: Route = {
      cost,
      distance,
      nodes: reachableNodes,
      segments,
      start: startPoint
    };
    return route;
  };

  findClosestNode = (address: string, target: Point2D) => {
    const { nearestNodes, nodes } = this;
    const loweredAddress = address?.toLowerCase();
    if (loweredAddress in nearestNodes) {
      return nearestNodes[loweredAddress];
    }
    const key = target.join(",");
    if (key in nodes) {
      return nodes[key];
    }

    return minBy(Object.values(this.nodes), (n) => getDistance(n!, target));
  };

  findNearestNodeByAddress = (address: string) => {
    const { nearestNodes } = this;
    const loweredAddress = address?.toLowerCase();
    if (loweredAddress in nearestNodes) {
      return nearestNodes[loweredAddress];
    }

    return undefined;
  };

  findNearestNodeAddress = (target: Point3D) => {
    const { nearestNodeAddresses } = this;
    const key = target.join(",");
    if (key in nearestNodeAddresses) {
      return nearestNodeAddresses[key];
    }

    return undefined;
  };

  private resolveNode = (node: Point3D): string => {
    const { nodes, adjacencyList } = this;
    const key = node.join(",");
    if (!nodes[key]) {
      nodes[key] = node;
      adjacencyList[key] = [];
    }
    return key!;
  };

  private addEdges = (n1: Point3D, n2: Point3D) => {
    const [n1Label, n2Label] = [n1, n2].map(this.resolveNode);
    const weight = getDistance(n1, n2);
    const angle = getAngle(n1, n2);
    this.adjacencyList[n1Label]!.push({ node: n2Label, weight, angle: angle, storey: n2[2] });
    this.adjacencyList[n2Label]!.push({
      node: n1Label,
      weight,
      angle: (angle + 180) % 360,
      storey: n1[2]
    });
  };

  private addNearestNode(address: string, node: Point3D) {
    const key = node.join(",");
    node = this.nodes[key];
    if (node) {
      this.nearestNodes[address] = node;
      this.nearestNodeAddresses[key] = address;
    }
  }

  private simplifyPath = (path: Point3D[]) => {
    const simplified: Point3D[] = [path[0]];
    for (let i = 1; i < path.length - 1; i++) {
      const prevAngle = getAngle(path[i - 1], path[i]);
      const nextAngle = getAngle(path[i], path[i + 1]);
      const prevStorey = path[i - 1][2];
      const nextStorey = path[i + 1][2];
      const currentStorey = path[i][2];

      if (prevAngle !== nextAngle || currentStorey !== nextStorey || currentStorey !== prevStorey) {
        simplified.push(path[i]);
      }
    }
    simplified.push(...path.slice(-1));
    return simplified;
  };

  private isGroupedByLevel = (items: string[]): boolean => {
    const group: Dictionary<string[]> = {};
    let prevStorey = "";

    for (let i = 0; i < items.length; i++) {
      //will be in this format [xAxis, yAxis, zAxis], eg: [127878, -234527, 2]
      const itemAddressList = items[i].split(",");
      const currentStorey = itemAddressList[2];

      if (prevStorey !== currentStorey && group[currentStorey]?.length > 0) {
        return false;
      }

      group[currentStorey] = (group[currentStorey] || []).concat(items[i]);
      prevStorey = currentStorey;
    }
    return true;
  };

  private compareNumber = (a: number, b: number): number => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  };

  get cacheData(): GraphProperties {
    const { nodes, adjacencyList, nearestNodes, nullNodes, nearestNodeAddresses } = this;
    return { nodes, adjacencyList, nearestNodes, nullNodes, nearestNodeAddresses };
  }

  static fromCacheData({
    nodes,
    adjacencyList,
    nearestNodes,
    nullNodes,
    nearestNodeAddresses
  }: GraphProperties): Graph | undefined {
    const graph = new GraphImpl();
    if (nodes && adjacencyList && nearestNodes && nullNodes) {
      graph.nodes = nodes;
      graph.adjacencyList = adjacencyList;
      graph.nearestNodes = nearestNodes;
      graph.nullNodes = nullNodes;
      graph.nearestNodeAddresses = nearestNodeAddresses;
      return graph;
    }
  }
}
