import TransitionInterpolator from "@deck.gl/core/dist/es5/transitions/transition-interpolator.js";
import { Point } from "@deck.gl/core";

interface Props {
  target: Point;
  zoom: number;
}

const VIEWSTATE_PROPS: (keyof Props)[] = ["target", "zoom"];

export default class OrthographicInterpolator extends TransitionInterpolator {
  constructor() {
    super(VIEWSTATE_PROPS);
  }

  arePropsEqual(currentProps: Props, nextProps: Props) {
    if (currentProps.target.join() !== nextProps.target.join()) {
      return false;
    }

    if (currentProps.zoom !== nextProps.zoom) {
      return false;
    }

    return true;
  }

  interpolateProps(startProps: Props, endProps: Props, t: number) {
    const { linearInterpolate } = this;
    const [startX, startY, startZ] = startProps.target!;
    const [endX, endY, endZ] = endProps.target!;
    const target = [
      linearInterpolate(startX, endX, t),
      linearInterpolate(startY, endY, t),
      startZ && endZ ? linearInterpolate(startZ, endZ, t) : undefined
    ];
    const startZoom = startProps.zoom;
    const endZoom = endProps.zoom;
    const zoom = linearInterpolate(startZoom, endZoom, t);
    return { target, zoom };
  }

  linearInterpolate(start: number, end: number, t: number) {
    return start + (end - start) * t;
  }
}
