import { useMemo } from "react";
import { Point, Color } from "@deck.gl/core";
import colors from "../constants/colors";
import { WaypointDetail } from "../types";

export interface Stop {
  number: number;
  address: string;
  point: Point;
  points: Point[];
  visited: boolean;
  color: Color;
  //   timestamps: number[];
}

export interface Directions {
  stops: Stop[];
}

// TODO: filter by storey
export function useDirections(waypoints: WaypointDetail[]) {
  const directions = useMemo(() => {
    const stops = waypoints
      .map(({ number, address, point, path, items }) => ({
        number,
        address,
        point,
        points: path.sections.map((s: any) => s.point),
        visited: items.every((i) => i.checked),
        color: number === 1 ? colors.nextStop : colors.remainingStops
      }))
      .reverse();
    return {
      stops
    };
  }, [waypoints]);
  return directions;
}
