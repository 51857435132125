export enum MapMode {
  store = "store",
  location = "location",
  list = "list"
}

export enum MapEventName {
  // Raised when the `StoreMap.render()` method becomes available
  ready = "STOREMAP_READY",
  // Raised when store is loaded
  store = "STOREMAP_STORE",
  // Raised when the map is first rendered
  rendering = "STOREMAP_RENDERING",
  // Raised when the map is first rendered
  rendered = "STOREMAP_RENDERED",
  // Raised when waypoints are updated
  waypointUpdate = "STOREMAP_WAYPOINTS",
  // Raised when the user interacts with a waypoint or visited location
  waypointFocus = "STOREMAP_WAYPOINT_FOCUS",
  // Raised when the user interacts with the start pin
  startAddressFocus = "STOREMAP_STARTADDRESS_FOCUS",
  // Raised when the map is cleared (e.g. when an invalid store number is provided)
  cleared = "STOREMAP_CLEARED",
  // Raised when the user swipes between products and a storey/floor change has occurred
  storeyChanged = "STOREMAP_STOREY_CHANGED",
  debug = "STOREMAP_DEBUG"
}
