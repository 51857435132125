import { Point } from "@deck.gl/core";

export interface Vector {
  dx: number;
  dy: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface Rectangle extends Size {
  x: number;
  y: number;
}

export interface Padding {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export type NamedPoint = [string, Point];

export enum NamedPointIndex {
  name = 0,
  point = 1
}

export type Polygon = Point[];

export type NamedPolygon = [string, Polygon, number];

export enum NamedPolygonIndex {
  name = 0,
  polygon = 1,
  area = 2
}
