export type ServiceType =
| "Assembly"
| "ClickAndCollect"
| "ColourMatching"
| "CourtesyTrailer"
| "CustomMadeProducts"
| "Delivery"
| "DisplayKitchens"
| "DriveCollect"
| "FreeTinting"
| "GasSwap"
| "GiftCards"
| "HardwareCafe"
| "HireShop"
| "Installation"
| "KeyCutting"
| "KidsPlayground"
| "PaintSwatches"
| "SpecialOrders"
| "TimberCutting"
| "ToolSharpening"
| "TradeAccount"
| "TradeDeliveries";

export interface Service {
  name: string;
  icon: string;
  location: string;
  storeyNumber: number;
}


export interface ServiceDTO {
  name: string;
  // Deprecated, remove once all maps have been published
  icon?: string;
  location: string;
  storeyNumber: number;
  // Once all maps have been published this can be made required
  type?: SerciceType
}

export interface ServiceMap {
  type: ServiceType,
  name: string,
  iconUrl: string
}

export const SERVICES: Readonly<ServiceMap[]> = [
  ["Assembly", "Assembly", "https://media.prod.bunnings.com.au/api/public/content/de6198701d4b4bcfb96272bd7cf74447?v=1eeeb876&t=gen58x58"],
  ["ClickAndCollect", "Click & Collect", "https://media.prod.bunnings.com.au/api/public/content/b5a8bfe701ea428fa53d37c1a370bcae?v=624e8dd9&t=gen58x58"],
  ["ColourMatching", "Colour Matching", "https://media.prod.bunnings.com.au/api/public/content/343a9776ffe344de86643da82d25dd14?v=4fa2fa6a&t=gen58x58"],
  ["CourtesyTrailer", "Courtesy Trailer", "https://media.prod.bunnings.com.au/api/public/content/2800b7d2475a4fd7b23e48cb39e5e82e?v=ead2e2c4&t=gen58x58"],
  ["CustomMadeProducts", "Custom Made Products", "https://media.prod.bunnings.com.au/api/public/content/5e424f01cbad43e0b3f9103318b6569e?v=2aaee341&t=gen58x58"],
  ["Delivery", "Delivery", "https://media.prod.bunnings.com.au/api/public/content/853ad1b86db44fa99a78f46760add851?v=54c7761b&t=gen58x58"],
  ["DisplayKitchens", "Display Kitchens", "https://media.prod.bunnings.com.au/api/public/content/b7a39fe4b3874395ab94d79b48b001b7?v=0507e629&t=gen58x58"],
  ["DriveCollect", "Drive & Collect", "https://media.prod.bunnings.com.au/api/public/content/f7d83092fe234ceca6eae21c910abd02?v=dca88bb6&t=gen58x58"],
  ["FreeTinting", "Free Tinting", "https://media.prod.bunnings.com.au/api/public/content/e97530c34e844c5ba58e7dde32661858?v=9ebb4476&t=gen58x58"],
  ["GasSwap", "Gas Swap", "https://media.prod.bunnings.com.au/api/public/content/b1bd111f68984376988abe03aeebbfe3?v=c2ca738e&t=gen58x58"],
  ["GiftCards", "Gift Cards", "https://media.prod.bunnings.com.au/api/public/content/6e0efdb27fd34b189a65591018fba9b2?v=399bd340&t=gen58x58"],
  ["HardwareCafe", "Hardware Cafe", "https://media.prod.bunnings.com.au/api/public/content/f230c810a27d48bd9b12abe9df0eeaf1?v=95ea23f2&t=gen58x58"],
  ["HireShop", "Hire Shop", "https://media.prod.bunnings.com.au/api/public/content/d95dbb0da70a48519c59cae26b96fe3a?v=c215bddb&t=gen58x58"],
  ["Installation", "Installation", "https://media.prod.bunnings.com.au/api/public/content/de6198701d4b4bcfb96272bd7cf74447?v=1eeeb876&t=gen58x58"],
  ["KeyCutting", "Key Cutting", "https://media.prod.bunnings.com.au/api/public/content/7e84ed5ec1e5443490b48bdb6d1b16e8?v=9c8c8c36&t=gen58x58"],
  ["KidsPlayground", "Kids Playground", "https://media.prod.bunnings.com.au/api/public/content/7e84ed5ec1e5443490b48bdb6d1b16e8?v=9c8c8c36&t=gen58x58"],
  ["PaintSwatches", "Paint Swatches", "https://media.prod.bunnings.com.au/api/public/content/ebfae3e49db74d4ca7293153799f2b17?v=23d88d5a&t=gen58x58"],
  ["SpecialOrders", "Special Orders", "https://media.prod.bunnings.com.au/api/public/content/283d37c3681e4285b319ae4021c73537?v=a2b643d9&t=gen58x58"],
  ["TimberCutting", "Timber Cutting", "https://media.prod.bunnings.com.au/api/public/content/2fd30faf741443ebadfd732061b8405d?v=cf829736&t=gen58x58"],
  ["ToolSharpening", "Tool Sharpening", "https://media.prod.bunnings.com.au/api/public/content/9bffe8c2a7bf4aeca5d2af3379c1dbd2?v=447b818c&t=gen58x58"],
  ["TradeAccount", "Trade Account", "https://media.prod.bunnings.com.au/api/public/content/32b90b7abb0248b497899090cc0a98d8?v=7377a20c&t=gen58x58"],
  ["TradeDeliveries", "Trade Deliveries", "https://media.prod.bunnings.com.au/api/public/content/d2559a6881a643d994e7576e7f519f8e?v=f9f4169e&t=gen58x58"]
].map(([type, name, iconUrl]) => ({ type, name, iconUrl } as ServiceMap));
