import { useMemo } from "react";
import colors, { ShapeColors } from "../constants/colors";
import { group } from "../services";
import { ListItem, MapMode, Polygon, StoreData } from "../types";

const SELECTED: Readonly<ShapeColors> = {
  fill: colors.selectedElement,
  stroke: colors.selectedElementBorder,
  text: colors.selectedElementLabel
};
const INACTIVE_SELECTED: Readonly<ShapeColors> = {
  fill: colors.inactiveSelectedElement,
  stroke: colors.inactiveSelectedElementBorder,
  text: colors.inactiveSelectedElementLabel
};

export interface SelectedElement {
  address: string;
  polygon: Polygon;
  colors: Readonly<ShapeColors>;
}

function getSingleLocationElements(
  store: StoreData,
  address: string | undefined,
  colors = SELECTED
) {
  const polygons = address ? store.locations[address] : [];
  if (!polygons?.length) return [];
  return polygons.map((polygon: any) => ({ address: address!, polygon, colors }));
}

function getListElements(store: StoreData, list: ListItem[] | undefined) {
  const checkListByAddress = group(
    list ?? [],
    (item) => item.address,
    (item) => item.checked
  );
  return Object.entries(checkListByAddress).flatMap(([address, checked]) => {
    const polygons = store.locations[address];
    if (!polygons?.length) return [];

    const colors = (checked as boolean[]).every((x) => x) ? INACTIVE_SELECTED : SELECTED;
    return polygons.map((polygon) => ({ address, polygon, colors }));
  });
}

export function useSelectedElements(
  store: StoreData | undefined,
  mode: MapMode,
  location: string | undefined,
  list: ListItem[] | undefined,
  selectedStorey: number | undefined
) {
  const selectedElements = useMemo((): SelectedElement[] => {
    if (!store) return [];
    switch (mode) {
      case MapMode.store:
      case MapMode.location:
        return getSingleLocationElements(store, location);
      case MapMode.list:
        return getListElements(store, list);
    }
  }, [store, mode, location, list, selectedStorey]);
  return selectedElements;
}
