import axios from "axios";
import { group, applyStorey, fixMapInconsistencies, getDisplayName } from "../services";
import Cache from "../utils/cache";
import {
  AddressableLocations,
  Bay,
  BayIndex,
  BayLocation,
  Dictionary,
  LegacyMapData,
  MapData,
  NamedPolygon,
  Polygon
} from "../types";
import { DAY } from "../constants/util";

const api = axios.create({
  baseURL: `/api`
});

const cache = new Cache("maps", 365 * DAY);

export function getBayAddress([aisle, bay]: Bay | BayLocation) {
  const paddedAisle = aisle.toString().padStart(3, "0");
  if (!bay) return paddedAisle;
  return [paddedAisle, bay.toString().padStart(3, "0")].join(".");
}

function isAddressable(bay: Bay) {
  return !!bay[BayIndex.aisle] && !!bay[BayIndex.bay];
}

export function getAddressableLocations(map: MapData): [AddressableLocations, Dictionary<string>] {
  const result: AddressableLocations = {};
  const defaultLandmarks: Dictionary<string> = {};
  for (const storey of map.storeys) {
    const aisles = group(
      storey.bays.concat(storey.promoBays).filter(isAddressable),
      ([aisle]) => getBayAddress([aisle]),
      (b) => b
    );

    for (const [aisleAddress, bays] of Object.entries(aisles)) {
      result[aisleAddress] = (bays as Bay[]).map((b) => b[BayIndex.polygon]);
      for (const bay of bays as Bay[]) {
        result[getBayAddress(bay)] = [bay[BayIndex.polygon]];
      }
    }

    const landmarks = group(
      storey.landmarks,
      ([name]) => name,
      ([, polygon]) => polygon
    );

    for (const [name, polygons] of Object.entries(landmarks)) {
      result[name] = polygons as Polygon[];

      const defaultName = getDisplayName(name);
      if (!defaultLandmarks[defaultName]) {
        defaultLandmarks[defaultName] = name;
      }
    }

    const departments = group(
      storey.departments,
      ([name]) => name,
      ([, polygon]) => polygon
    );

    for (const [name, polygons] of Object.entries(departments)) {
      result[`department:${name}`] = polygons as Polygon[];
    }

    for (const [name, point] of storey.entrances) {
      result[`entrance:${name}`] = [[point]];
    }

    for (const [name, point] of storey.pointsOfInterest) {
      result[`point:${name}`] = [[point]];
    }

    for (const [name, polygon] of storey.levelAccess) {
      result[`levelaccess:${name}`] = [polygon];
    }
    storey.landmarks = (storey.landmarks as NamedPolygon[]).reduce(
      (group, [address, polygon, center]) => {
        group.push([getDisplayName(address), polygon, center]);
        return group;
      },
      [] as NamedPolygon[]
    );
  }

  return [result, defaultLandmarks];
}

export async function getMap(storeNumber: string, mapId: string): Promise<MapData> {
  // TODO: delete isLegacyMap once all legacy maps have been published
  let isLegacyMap = false;
  const cacheKey = `${storeNumber}/${mapId}-v3`;
  return cache.getOrAdd(cacheKey, async () => {
    const { data } = await api.get<MapData | LegacyMapData>(`/${storeNumber}/${mapId}-map.json`);
    let map: MapData;
    // TODO: Delete this once all maps are published into the new format
    if ("storeys" in data) {
      map = data;
    } else {
      isLegacyMap = true;
      const { mapId, storeNumber, storeName, ...storey } = data;
      map = {
        mapId,
        storeNumber,
        storeName,
        storeys: [
          {
            ...storey,
            storeyNumber: 1, // Existing legacy maps only have 1 store
            name: "Ground",
            nameAbbreviated: "G",
            levelAccess: []
          }
        ]
      };
    }

    // TODO: delete isLegacyMap condition once all legacy maps have been published
    if (isLegacyMap) {
      map = applyStorey(map);
    }
    map = fixMapInconsistencies(storeNumber, map);

    return map;
  });
}
