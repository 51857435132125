import localForage from "localforage";
import { name as APP_NAME } from "../../package.json";
import { MINUTE } from "../constants/util";

interface CacheEntry<T> {
  data: T;
  expiry: Date;
}


export default class Cache {
  storage!: LocalForage;
  constructor(name: string, public defaultTTLInSeconds = 15 * MINUTE) {
    name = `${APP_NAME}:${name}`;
    this.storage = localForage.createInstance({ name });
  }

  async get<T>(key: string) {
    const entry = await this.storage.getItem<CacheEntry<T>>(key);
    if (entry && entry.expiry > new Date()) {
      return entry.data;
    }

    this.storage.removeItem(key).catch(() => {});
    return undefined;
  }

  async getOrAdd<T>(
    key: string,
    factory: (key: string) => T | Promise<T>,
    ttl: number = this.defaultTTLInSeconds
  ) {
    let data = await this.get<T>(key);
    if (data === undefined) {
      data = await factory(key);
      this.set(key, data, ttl);
    }
    return data;
  }

  async set<T>(key: string, data: T, ttlInSeconds: number = this.defaultTTLInSeconds) {
    const expiry = new Date();
    expiry.setTime(expiry.getTime() + ttlInSeconds * 1000);
    return this.storage.setItem(key, { data, expiry }).then(() => {});
  }

  async trim() {
    await Promise.all((await this.storage.keys()).map((key) => this.get(key)));
  }

  remove(key: string) {
    return this.storage.removeItem(key);
  }
}
