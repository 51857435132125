import { Color } from "@deck.gl/core";

export interface ColorDefinitions {
  [key: string]: Color;
}

export interface ShapeColors {
  fill: Color;
  stroke: Color;
  text: Color;
}

const colors = {
  background: hexToRGB("#dbe5e5"),
  outline: hexToRGB("#a7bfbf"),
  shopFloor: hexToRGB("#fbfeff"),
  aisleNumbers: hexToRGB("#0f5256"),
  departmentName: hexToRGB("#757575"),
  landmark: hexToRGB("#e2ecee"),
  landmarkLabel: hexToRGB("#0f5256"),
  landmarkBorder: hexToRGB("#b9cdcf"),
  specialOrdersDesk: hexToRGB("#e5f5ff"),
  specialOrdersDeskLabel: hexToRGB("#007dc7"),
  specialOrdersDeskBorder: hexToRGB("#b2dbf6"),
  registers: hexToRGB("#fff1d7"),
  registersLabel: hexToRGB("#fea920"),
  registersBorder: hexToRGB("#ffdea3"),
  inactiveElement: hexToRGB("#edf3f3"),
  inactiveElementBorder: hexToRGB("#edf3f3"),
  bay: hexToRGB("#edf3f3"),
  bayBorder: hexToRGB("#edf3f3"),
  selectedElement: hexToRGB("#f8dddb"),
  selectedElementBorder: hexToRGB("#da291c"),
  selectedElementLabel: hexToRGB("#da291c"),
  inactiveSelectedElement: hexToRGB("#c5d8da"),
  inactiveSelectedElementBorder: hexToRGB("#0d5257"),
  inactiveSelectedElementLabel: hexToRGB("#0d5257"),
  kiosk: hexToRGB("#d92721"),
  mapLocation: hexToRGB("#d92721"),
  highlightedBay: hexToRGB("#f7e8e8"),
  highlightedBayBorder: hexToRGB("#d92721"),
  nextStop: hexToRGB("#83a8ab"),
  remainingStops: hexToRGB("#cbdcdd"),
  entrances: hexToRGB("#0f5256"),
  levelAccessName: hexToRGB("#757575"),
  levelAccess: hexToRGB("#fbecdf"),
  levelAccessBorder: hexToRGB("#e87e2c"),
  textStroke: hexToRGB("#ffffff")
};
export default colors;
export const hexColors = Object.fromEntries(
  Object.entries(colors).map(([key, color]) => [key, rgbToHex(color)])
) as Record<keyof typeof colors, string>;

export function hexToRGB(hex: string): Color {
  const match = /([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/g.exec(hex);
  if (match) {
    return match.slice(1, 4).map((x) => parseInt(x, 16)) as Color;
  }
  return [0, 0, 0, 0];
}

export function rgbToHex(rgb: Color): string {
  return ["#"].concat(rgb.map((x) => x.toString(16).padStart(2, "0"))).join("");
}

export function interpolate(value: number, min: Color, max: Color): Color {
  if (value <= 0) return min;
  if (value >= 1) {
    return max;
  }
  const [minR, minG, minB] = min;
  const [maxR, maxG, maxB] = max;
  return [
    minR + Math.round((maxR - minR) * value),
    minG + Math.round((maxG - minG) * value),
    minB + Math.round((maxB - minB) * value)
  ];
}
